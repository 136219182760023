// frontend/src/components/mobile/MobileLanding.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Bot, Sparkles, ArrowRight, Zap, Brain, HandCoins } from 'lucide-react';
import textLogoDark from '../../assets/textlogo_dark.png';
import CostCalculator from '../CostCalculator';
import BenchmarkSection from '../BenchmarkSection';

const MobileLanding = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 overflow-hidden px-4 pb-safe-area-inset-bottom">
      {/* Logo Section */}
      <motion.div 
        className="pt-8 pb-6"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <img src={textLogoDark} alt="FlexFlow AI" className="w-48 mx-auto" />
      </motion.div>

      {/* Hero Content */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center mb-8"
      >
        <h1 className="text-3xl font-extralight mb-4 bg-gradient-to-r from-white via-theme-400 to-theme-600 bg-clip-text text-transparent px-4">
          Experience the Future of{' '}
          <span className="bg-gradient-to-r from-theme-400 via-theme-500 to-theme-600 bg-clip-text">
            AI Interaction
          </span>
        </h1>
        
        <p className="text-lg text-gray-300 font-light mb-6 px-4">
          Seamlessly switch AI models, generate images, and pay-as-you-go—all in one platform.
        </p>

        <motion.button
          onClick={() => navigate('/login')}
          className="group w-full px-6 py-4 bg-theme-500 rounded-lg text-white text-lg font-medium hover:bg-theme-600 transition-all duration-300 flex items-center justify-center gap-2"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Get Started with 100 Free Credits
          <ArrowRight className="group-hover:translate-x-1 transition-transform" />
        </motion.button>
      </motion.div>

      {/* Feature Grid */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="grid grid-cols-1 gap-4 mb-12"
      >
        {[
          {
            icon: <Bot size={24} />,
            title: "Model Flexibility",
            desc: "Switch between AI models seamlessly mid-conversation"
          },
          {
            icon: <Sparkles size={24} />,
            title: "Image Generation",
            desc: "Create stunning visuals from text descriptions for just ₹0.50/image"
          },
          {
            icon: <Zap size={24} />,
            title: "Pay As You Go",
            desc: "Pay per 500 tokens, only for what you use"
          },
          {
            icon: <HandCoins size={24} />,
            title: "100 Free Credits",
            desc: "Start your journey with complimentary credits"
          }
        ].map((feature, index) => (
          <motion.div
            key={index}
            className="p-4 rounded-xl bg-gray-800/50 border border-gray-700 hover:bg-gray-700/50 transition-all duration-300"
            whileHover={{ y: -2 }}
          >
            <div className="flex items-center gap-3 mb-2">
              <div className="text-theme-500">{feature.icon}</div>
              <h3 className="text-sm font-medium text-white">{feature.title}</h3>
            </div>
            <p className="text-sm text-gray-400">{feature.desc}</p>
          </motion.div>
        ))}
      </motion.div>

      {/* Cost Calculator */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
        className="mb-12"
      >
        <CostCalculator />
      </motion.div>

      {/* Benchmark Section */}
      <BenchmarkSection />
    </div>
  );
};

export default MobileLanding;