import React from 'react';
import { LegalPage, List, Paragraph, Section } from './LegalPage';

const PrivacyPolicy = () => {
  const sections = [
    {
      title: "1. Information We Collect",
      content: (
        <Section>
          <h3 className="text-lg font-medium text-white mb-2">Account Information</h3>
          <List items={[
            "Name and email address for account creation and identification",
            "Account credentials (encrypted password)",
            "Payment information when purchasing credits (processed securely through Razorpay)",
            "Profile settings and preferences"
          ]} />

          <h3 className="text-lg font-medium text-white mb-2 mt-4">Usage Information</h3>
          <List items={[
            "Chat history and generated content from model interactions",
            "Credit usage and transaction history",
            "Service interaction patterns and settings preferences",
            "Technical data such as device type, browser information, and IP address",
            "Time and duration of service usage"
          ]} />
          
          <h3 className="text-lg font-medium text-white mb-2 mt-4">User Content</h3>
          <List items={[
            "Text prompts and inputs provided to AI models",
            "Generated images and their associated prompts",
            "Chat conversations and context",
            "Saved preferences and custom settings"
          ]} />
        </Section>
      )
    },
    {
      title: "2. How We Use Your Information",
      content: (
        <Section>
          <List items={[
            "To provide and maintain our AI chat and image generation services",
            "To process payments and manage credit transactions",
            "To improve our service quality and user experience",
            "To detect and prevent fraudulent activity or service abuse",
            "To communicate service updates, maintenance notices, and promotional offers",
            "To provide customer support and respond to inquiries",
            "To analyze usage patterns and optimize service performance",
            "To ensure compliance with our terms of service"
          ]} />
        </Section>
      )
    },
    {
      title: "3. Data Storage and Security",
      content: (
        <Section>
          <Paragraph>
            We implement industry-standard security measures to protect your data, including:
          </Paragraph>
          <List items={[
            "End-to-end encryption for sensitive data transmission",
            "Secure storage of chat histories and generated content",
            "Regular security audits and vulnerability assessments",
            "Strict access controls and authentication protocols",
            "Regular data backups and disaster recovery procedures"
          ]} />
          <Paragraph>
            All data is stored securely in our cloud infrastructure with regular monitoring and security updates.
          </Paragraph>
        </Section>
      )
    },
    {
      title: "4. Content Moderation",
      content: (
        <Section>
          <Paragraph>
            Our content moderation system helps maintain a safe and respectful environment:
          </Paragraph>
          <List items={[
            "Automated analysis of user prompts for policy compliance",
            "Real-time filtering of inappropriate or harmful content",
            "No storage of rejected prompts or filtered content",
            "Regular updates to our moderation algorithms",
            "Manual review process for appealing moderation decisions"
          ]} />
        </Section>
      )
    },
    {
      title: "5. Data Sharing and Third Parties",
      content: (
        <Section>
          <Paragraph>
            We may share data with third parties under these circumstances:
          </Paragraph>
          <List items={[
            "Payment processors for handling transactions (Razorpay)",
            "Cloud service providers for infrastructure and hosting",
            "AI model providers for processing requests",
            "Analytics services for performance monitoring",
            "Law enforcement when required by valid legal process"
          ]} />
          <Paragraph>
            We never sell your personal information to third parties for marketing purposes.
          </Paragraph>
        </Section>
      )
    },
    {
      title: "6. User Rights and Control",
      content: (
        <Section>
          <Paragraph>
            You have the following rights regarding your data:
          </Paragraph>
          <List items={[
            "Access your personal data and chat history",
            "Request data export in a machine-readable format",
            "Delete your account and associated data",
            "Opt-out of promotional communications",
            "Update or correct your personal information",
            "Request limitation of data processing",
            "Object to certain data processing activities"
          ]} />
        </Section>
      )
    },
    {
      title: "7. Cookies and Tracking",
      content: (
        <Section>
          <List items={[
            "Essential cookies for maintaining user sessions",
            "Authentication tokens for secure access",
            "Performance cookies for service optimization",
            "No third-party tracking cookies used",
            "Users can control cookie preferences through browser settings"
          ]} />
        </Section>
      )
    },
    {
      title: "8. Age Restrictions",
      content: (
        <Section>
          <Paragraph>
            Our service is not intended for users under 13 years of age. We do not knowingly collect 
            information from children under 13. If you become aware that a child has provided us with 
            personal information, please contact us to have the data removed.
          </Paragraph>
        </Section>
      )
    },
    {
      title: "9. Updates to Privacy Policy",
      content: (
        <Section>
          <List items={[
            "We may update this policy to reflect service changes",
            "Users will be notified of significant changes via email",
            "Continued use after changes implies acceptance",
            "Previous versions will be archived and available upon request",
            "Changes will not be retroactively applied"
          ]} />
        </Section>
      )
    },
    {
      title: "10. Contact Information",
      content: (
        <Section>
          <Paragraph>
            For privacy-related inquiries or concerns:
          </Paragraph>
          <List items={[
            "Email: social@flexflow.in",
            "Support portal: support.flexflow.in",
            "Response time: Within 48 hours",
            "Address: Mumbai, India"
          ]} />
        </Section>
      )
    }
  ];

  return <LegalPage title="Privacy Policy" sections={sections} />;
};

export default PrivacyPolicy;