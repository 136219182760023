import React, { useState } from 'react';
import { Upload, Loader2, AlertCircle } from 'lucide-react';
import axios from 'axios';
import { MODEL_CONFIG } from '../config/modelConfig';

const FileUpload = ({ isDarkMode, onFileUpload, selectedModel }) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);

  const modelConfig = MODEL_CONFIG[selectedModel];
  if (!modelConfig?.supportsFiles) return null;

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!Object.keys(modelConfig.allowedFileTypes).includes(file.type)) {
      setError(`Invalid file type. Allowed: ${Object.values(modelConfig.allowedFileTypes).join(', ')}`);
      setTimeout(() => setError(''), 5000);
      return;
    }

    if (file.size > modelConfig.maxSize) {
      setError(`File too large. Max: ${modelConfig.maxSize / (1024 * 1024)}MB`);
      setTimeout(() => setError(''), 5000);
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('/api/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      onFileUpload({
        ...response.data,
        originalName: file.name,
        file: file
      });
    } catch (error) {
      setError(error.response?.data?.detail || 'Upload failed');
      setTimeout(() => setError(''), 5000);
    } finally {
      setUploading(false);
      e.target.value = '';
    }
  };

  return (
    <div className="relative">
      <input type="file" onChange={handleFileChange} className="hidden" id="file-upload" 
             accept={Object.values(modelConfig.allowedFileTypes).join(',')} />
      <div className="relative">
        <label htmlFor="file-upload" className={`p-2 rounded-lg cursor-pointer flex items-center justify-center transition-colors ${
          isDarkMode ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-100 text-gray-500'}`}
          onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
          {uploading ? <Loader2 className="animate-spin" size={18} /> : <Upload size={18} />}
        </label>
        {showTooltip && (
          <div className={`absolute right-full top-1/2 transform -translate-y-1/2 mr-2 px-3 py-2 text-xs rounded-lg shadow-lg whitespace-nowrap ${
            isDarkMode ? 'bg-gray-800 text-gray-200' : 'bg-white text-gray-800'}`}>
            Supported: {Object.values(modelConfig.allowedFileTypes).join(', ')}
            <div className="absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 rotate-45 w-2 h-2 bg-inherit"></div>
          </div>
        )}
      </div>
      {error && (
        <div className={`absolute bottom-full left-0 mb-2 px-3 py-2 rounded-lg text-xs whitespace-nowrap flex items-center gap-2 ${
          isDarkMode ? 'bg-red-900/50 text-red-200' : 'bg-red-100 text-red-600'}`}>
          <AlertCircle size={14} />{error}
        </div>
      )}
    </div>
  );
};

export default FileUpload;