import React, { useState, useEffect } from 'react';
import { LogOut, MessageCircle, MessageCirclePlus, Search, Bot, Image, Trash2, PencilLine, Settings } from 'lucide-react';
import CreditsManager from '../CreditsManager';
import axios from 'axios';
import InfoModal from '../modals/InfoModal';

const Sidebar = React.forwardRef(({ 
  currentUser, 
  isDarkMode, 
  handleLogout, 
  flowLogo,
  onChatSelect,
  selectedChatId,
  onNewChat,
  activeTab,
  setActiveTab
}, ref) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingChatId, setEditingChatId] = useState(null);
  const [editingName, setEditingName] = useState('');
  const [showInfoModal, setShowInfoModal] = useState(false);

  const fetchChatHistory = async () => {
    if (!currentUser?.id) return;
    
    try {
      setIsLoading(true);
      const response = await axios.get(`/api/chat/history/${currentUser.id}`);
      const sortedChats = response.data.sort((a, b) => 
        new Date(b.timestamp) - new Date(a.timestamp)
      );
      setChatHistory(sortedChats);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser?.id) {
      fetchChatHistory();
    }
  }, [currentUser?.id]);

  React.useImperativeHandle(ref, () => ({
    fetchChatHistory
  }));

  const handleDeleteChat = async (chatId, e) => {
    e.stopPropagation();
    try {
      const response = await axios.delete(`/api/chat/${chatId}?user_id=${currentUser.id}`);
      if (response.data.status === 'success') {
        if (selectedChatId === chatId) {
          onNewChat();
        } else {
          fetchChatHistory();
        }
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const handleUpdateChatName = async (chatId, title, e) => {
    e.stopPropagation();
    try {
        await axios.put(`/api/chat/title/${chatId}`, null, {
            params: { user_id: currentUser.id, title }
        });
        setEditingChatId(null);
        fetchChatHistory();
    } catch (error) {
        console.error('Error updating chat title:', error);
    }
};

  const handleStartEditing = (chat, e) => {
    e.stopPropagation();
    setEditingChatId(chat._id);
    setEditingName(chat.title || chat.lastMessage);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    fetchChatHistory(tab);
  };

  const filteredChats = chatHistory.filter(chat =>
    chat.lastMessage?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    chat.title?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={`w-64 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-r flex flex-col h-full`}>
      {/* Logo Header */}
      <div className={`h-[50px] flex items-center px-14 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
        <div className="flex items-center space-x-0">
          <img src={flowLogo} alt="Flow AI Logo" className="h-8 w-8" />
          <h1 className={`text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            FlexFlow
          </h1>
        </div>
      </div>

      {/* Tabs */}
      <div className={`flex border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
        <button
          onClick={() => handleTabChange('chat')}
          className={`flex-1 p-3 flex flex-col items-center ${
            activeTab === 'chat' 
              ? 'border-b-2 border-theme-500 text-theme-500' 
              : isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}
        >
          <Bot size={16} className={`mb-1 ${activeTab === 'chat' ? 'text-theme-500' : ''}`} />
          <span className="text-xs items-center font-bold" >Chat Bot</span>
        </button>
        <button
          onClick={() => handleTabChange('image')}
          className={`flex-1 p-3 flex flex-col items-center ${
            activeTab === 'image' 
              ? 'border-b-2 border-theme-500 text-theme-500' 
              : isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}
        >
          <Image size={16} className={`mb-1 ${activeTab === 'image' ? 'text-theme-500' : ''}`} />
          <div className="text-xs flex items-center font-bold">
            Image Gen
          </div>
        </button>
      </div>

      {activeTab === 'chat' && (
        <>
          {/* Search Header */}
          <div className={`px-4 py-3 flex items-center ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
            <h3 className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              Chat History
            </h3>
            <div className="flex items-center space-x-1 ml-auto">
              <div className="relative group">
                <div className={`flex items-center overflow-hidden transition-all duration-300 ${
                  isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                } rounded-full group-hover:w-40 w-8 h-8`}>
                  <input
                    type="text"
                    placeholder="Look for chats..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={`absolute right-8 px-3 py-1.5 w-32 h-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ${
                      isDarkMode ? 'bg-transparent text-white placeholder-gray-400' : 'bg-transparent text-gray-900 placeholder-gray-500'
                    } focus:outline-none border-none text-sm`}
                  />
                  <Search size={18} className={`absolute right-2 ${isDarkMode ? 'text-gray-400 group-hover:text-gray-300' : 'text-gray-500 group-hover:text-gray-700'}`} />
                </div>
              </div>
              <button
                onClick={onNewChat}
                className={`p-1.5 rounded-full transition-colors ${
                  isDarkMode ? 'hover:bg-gray-700 text-gray-400 hover:text-gray-300' : 'hover:bg-gray-100 text-gray-600 hover:text-gray-800'
                }`}
                title="Start new chat"
              >
                <MessageCirclePlus size={18} />
              </button>
            </div>
          </div>

          {/* Chat List */}
          <div className="flex-1 overflow-y-auto px-4">
            {isLoading ? (
              <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                Loading chats...
              </div>
            ) : filteredChats.length === 0 ? (
              <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {searchTerm ? 'No matching chats found' : 'No chat history yet'}
              </div>
            ) : (
              <div className="space-y-2">
                {filteredChats.map((chat) => (
                  <div
                    key={chat._id}
                    className={`group p-2 rounded-lg cursor-pointer transition-colors flex justify-between items-center ${
                      selectedChatId === chat._id
                        ? isDarkMode 
                          ? 'bg-gray-700 text-white'
                          : 'bg-gray-200 text-gray-900'
                        : isDarkMode 
                          ? 'text-gray-300 hover:bg-gray-700'
                          : 'text-gray-600 hover:bg-gray-100'
                    }`}
                  >
                    <div 
                      className="flex items-center space-x-2 flex-1 min-w-0"
                      onClick={() => onChatSelect(chat._id)}
                    >
                      <MessageCircle 
                        size={16} 
                        className="w-4 h-4 flex-shrink-0" // Add fixed dimensions and prevent shrinking
                      />
                      {editingChatId === chat._id ? (
                        <input
                          type="text"
                          value={editingName}
                          onChange={(e) => setEditingName(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleUpdateChatName(chat._id, editingName, e);
                            } else if (e.key === 'Escape') {
                              setEditingChatId(null);
                            }
                          }}
                          onBlur={(e) => handleUpdateChatName(chat._id, editingName, e)}
                          onClick={(e) => e.stopPropagation()}
                          className={`text-sm w-full bg-transparent focus:outline-none ${
                            isDarkMode ? 'text-white' : 'text-gray-900'
                          }`}
                          autoFocus
                        />
                      ) : (
                        <span className="text-sm truncate">
                          {chat.title || (chat.lastMessage?.length > 30 
                            ? `${chat.lastMessage.substring(0, 30)}...` 
                            : chat.lastMessage)}
                        </span>
                      )}
                    </div>
                    <div className="flex space-x-1">
                      <button
                        onClick={(e) => handleStartEditing(chat, e)}
                        className={`p-1 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity ${
                          isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-300'
                        }`}
                      >
                        <PencilLine size={14} />
                      </button>
                      <button
                        onClick={(e) => handleDeleteChat(chat._id, e)}
                        className={`p-1 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity ${
                          isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-300'
                        }`}
                      >
                        <Trash2 size={14} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}

      {/* Image Tab Content */}
      {activeTab === 'image' && (
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center">
            <Image 
              size={48} 
              className={`mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} 
            />
            <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              Image Generation
            </p>
          </div>
        </div>
      )}

      {/* Credits Manager */}
      <div className="mx-4 mb-auto">
        <CreditsManager isDarkMode={isDarkMode} />
      </div>

      {/* User Profile Footer */}
      <div className={`h-[72px] p-4 border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
        <div className="flex items-center">
          <div className="flex-1 min-w-0">
            <p className={`text-sm font-medium truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {currentUser?.name || 'User'}
            </p>
            <p className={`text-xs truncate ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {currentUser?.email}
            </p>
          </div>
          <div className="flex items-center space-x-2">
          <button 
            onClick={() => setShowInfoModal(true)}
            className={`p-2 rounded-lg ${
              isDarkMode 
                ? 'hover:bg-gray-700 text-gray-400' 
                : 'hover:bg-gray-100 text-gray-600'
            }`}
            title="Info"
          >
            <Settings size={20} />
          </button>
          <button onClick={handleLogout}
            className={`p-2 rounded-lg ${
              isDarkMode 
                ? 'hover:bg-gray-700 text-gray-400' 
                : 'hover:bg-gray-100 text-gray-600'
            }`}
            title="Logout">
            <LogOut size={20} />
          </button>
        </div>
        <InfoModal 
          isOpen={showInfoModal}
          onClose={() => setShowInfoModal(false)}
          isDarkMode={isDarkMode}
        />
        </div>
      </div>
    </div>
  );
});

export default Sidebar;