import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { useEvent } from '../../contexts/EventContext';

const PurchaseCreditsModal = ({ isOpen, onClose, isDarkMode }) => {
  const [selectedAmount, setSelectedAmount] = useState(1000);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const { triggerCreditUpdate } = useEvent();

  const creditOptions = [
    { value: 100, label: '100 Credits', price: 49 },
    { value: 500, label: '500 Credits', price: 249 },
    { value: 1000, label: '1,000 Credits', price: 499 },
    { value: 3000, label: '3,000 Credits', price: 1299 },
    { value: 5000, label: '5,000 Credits', price: 1999 }
  ];

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async () => {
    try {
      setIsLoading(true);
      setError('');
      const selectedOption = creditOptions.find(opt => opt.value === selectedAmount);
      
      // Create a cleanup function to reset loading state
      const resetLoading = () => setIsLoading(false);
      
      // Create order
      const orderResponse = await axios.post('/api/payment/create-order', {
        amount: selectedOption.price,
        credits: selectedAmount
      });

      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: selectedOption.price * 100,
        currency: 'INR',
        name: 'FlexFlow AI',
        description: `Purchase ${selectedAmount} credits`,
        order_id: orderResponse.data.id,
        handler: async (response) => {
          try {
            // Verify payment
            await axios.post('/api/payment/verify-payment', {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature
            });

            // Update credits
            await axios.post(`/auth/user/purchase-credits/${currentUser.id}`, {
              amount: selectedAmount
            });
            
            // Update UI and reset states
            triggerCreditUpdate();
            resetLoading();
            onClose();
          } catch (error) {
            setError('Payment verification failed. Please contact support if credits were deducted.');
            resetLoading();
            console.error('Payment verification failed:', error);
          }
        },
        prefill: {
          email: currentUser.email,
          name: currentUser.name
        },
        theme: {
          color: '#6d28d9'
        },
        modal: {
          ondismiss: resetLoading,
          escape: true,
          backdropClose: true
        }
      };

      const razorpay = new window.Razorpay(options);
      razorpay.on('payment.failed', function (response) {
        setError('Payment failed. Please try again.');
        resetLoading();
      });
      razorpay.open();
    } catch (error) {
      setError('Error initiating payment. Please try again.');
      setIsLoading(false);
      console.error('Error initiating payment:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`w-full max-w-md p-6 relative rounded-lg ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      }`}>
        <button
          onClick={onClose}
          className={`absolute right-4 top-4 ${
            isDarkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          <X size={20} />
        </button>

        <h2 className={`text-2xl font-bold mb-6 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Purchase Credits
        </h2>

        {error && (
          <div className="mb-4 p-3 rounded-lg bg-red-100 border border-red-300 text-red-600">
            {error}
          </div>
        )}

        <div className="space-y-4 mb-6">
          {creditOptions.map((option) => (
            <button
              key={option.value}
              type="button"
              onClick={() => setSelectedAmount(option.value)}
              className={`w-full p-4 rounded-lg border-2 text-left transition-colors ${
                selectedAmount === option.value
                  ? 'border-theme-600 bg-theme-600 bg-opacity-10'
                  : isDarkMode
                    ? 'border-gray-700 hover:border-theme-600'
                    : 'border-gray-200 hover:border-theme-600'
              }`}
            >
              <div className={`font-medium ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {option.label}
              </div>
              <div className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                ₹{option.price.toLocaleString()}
              </div>
            </button>
          ))}
        </div>

        <button
          onClick={handlePayment}
          disabled={isLoading}
          className="w-full bg-theme-600 text-white py-3 rounded-lg hover:bg-theme-700 font-medium disabled:opacity-50 transition-colors"
        >
          {isLoading ? 'Processing...' : 'Purchase Credits'}
        </button>

        {/* <p className={`mt-4 text-sm text-center ${
          isDarkMode ? 'text-gray-400' : 'text-gray-500'
        }`}>
          Secure payments powered by Razorpay
        </p> */}
      </div>
    </div>
  );
};

export default PurchaseCreditsModal;