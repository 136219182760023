import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Lock, Mail, User, RefreshCw, Eye, EyeOff } from 'lucide-react';
import { useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import flowLogo from '../assets/flexflowai_logo.png';
import OnboardingModal from './modals/OnboardingModal';

const Login = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [otp, setOtp] = useState('');
  const [canResend, setCanResend] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const [otpSent, setOtpSent] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { login, signup } = useAuth();
  const navigate = useNavigate();

  const handleSendOTP = async () => {
    try {
      setIsLoading(true);
      // Check if user exists
      const userCheck = await axios.post('/auth/check-email', { email });
      
      if (userCheck.data.exists) {
        setError('Email already registered. Please sign in.');
        setIsSignUp(false);
        setIsLoading(false);
        return;
      }
      
      await axios.post('/auth/send-otp', { email });
      setOtpSent(true);
      setCanResend(false);
      let timer = 60;
      const interval = setInterval(() => {
        timer--;
        setCountdown(timer);
        if (timer === 0) {
          setCanResend(true);
          clearInterval(interval);
        }
      }, 1000);
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to send OTP');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (isSignUp) {
        if (password !== confirmPassword) {
          throw new Error('Passwords do not match');
        }

        if (!isVerifying) {
          await handleSendOTP();
          setIsVerifying(true);
          setIsLoading(false);
          return;
        }

        if (isVerifying) {
          await axios.post('/auth/verify-otp', { email, otp });
          await signup(name, email, password);
          setShowOnboarding(true);
        }
      } else {
        await login(email, password);
        navigate('/chat');
      }
    } catch (err) {
      setError(err.response?.data?.detail || err.message);
      setIsLoading(false);
    }
  };

  const toggleMode = () => {
    setIsSignUp(!isSignUp);
    setError('');
    setName('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setIsVerifying(false);
    setOtp('');
    setOtpSent(false);
  };

  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <div className="flex items-center justify-center">
              <img src={flowLogo} alt="Flow AI Logo" className="h-40 w-40" />
            </div>
            <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              FlexFlow
            </h1>
            <p className="mt-2 text-center text-sm text-gray-600">
              {isSignUp ? 'Create your account' : 'Sign in to your account'}
            </p>
          </div>

          {error && (
            <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
              {typeof error === 'object' ? error.msg || 'An error occurred' : error}
            </div>
          )}

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-lg shadow-sm space-y-4">
              {isSignUp && !isVerifying && (
                <div>
                  <label htmlFor="name" className="sr-only">Full Name</label>
                  <div className="flex items-center">
                    <User className="absolute ml-3 text-gray-400" size={20} />
                    <input
                      id="name"
                      name="name"
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="pl-10 appearance-none w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-theme-500 focus:border-theme-500"
                      placeholder="Full Name"
                    />
                  </div>
                </div>
              )}

              <div>
                <label htmlFor="email" className="sr-only">Email address</label>
                <div className="flex items-center">
                  <Mail className="absolute ml-3 text-gray-400" size={20} />
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isVerifying}
                    className="pl-10 appearance-none w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-theme-500 focus:border-theme-500"
                    placeholder="Email address"
                  />
                </div>
              </div>

              {isSignUp && isVerifying && (
                <div>
                  <label htmlFor="otp" className="sr-only">Verification Code</label>
                  <div className="flex items-center">
                    <Lock className="absolute ml-3 text-gray-400" size={20} />
                    <input
                      id="otp"
                      name="otp"
                      type="text"
                      required
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="pl-10 appearance-none w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-theme-500 focus:border-theme-500"
                      placeholder="Enter verification code"
                    />
                    <button
                      type="button"
                      onClick={handleSendOTP}
                      disabled={!canResend}
                      className="ml-2 px-4 py-2 rounded-lg bg-theme-500 text-white disabled:opacity-50 hover:bg-theme-600"
                    >
                      {canResend ? <RefreshCw size={20} /> : <span>{countdown}s</span>}
                    </button>
                  </div>
                  {otpSent && (
                    <p className="mt-2 text-sm text-green-600">
                      OTP sent! Check your email.
                    </p>
                  )}
                  <div className="mt-2 text-sm text-center text-gray-600">
                    By signing up, you agree to our{' '}
                    <Link to="/terms" className="text-theme-600 hover:text-theme-500">
                      Terms of Use
                    </Link>{' '}
                    and{' '}
                    <Link to="/privacy" className="text-theme-600 hover:text-theme-500">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              )}

            {(!isSignUp || !isVerifying) && (
              <>
                <div>
                  <label htmlFor="password" className="sr-only">Password</label>
                  <div className="flex items-center relative">
                    <Lock className="absolute left-3 text-gray-400" size={20} />
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="pl-10 pr-12 appearance-none w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-theme-500 focus:border-theme-500"
                      placeholder="Password"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 text-gray-400 hover:text-gray-600"
                    >
                      {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                  </div>
                </div>
                {isSignUp && (
                  <div>
                    <label htmlFor="confirmPassword" className="sr-only">Confirm Password</label>
                    <div className="flex items-center relative">
                      <Lock className="absolute left-3 text-gray-400" size={20} />
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="pl-10 pr-12 appearance-none w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-theme-500 focus:border-theme-500"
                        placeholder="Confirm Password"
                      />
                      <button
                        type="button"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        className="absolute right-3 text-gray-400 hover:text-gray-600"
                      >
                        {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                      </button>
                    </div>
                    <div className="mt-2 text-sm text-center text-gray-600">
                    By signing up, you agree to our{' '}
                    <Link to="/terms" className="text-theme-600 hover:text-theme-500">
                      Terms of Use
                    </Link>{' '}
                    and{' '}
                    <Link to="/privacy" className="text-theme-600 hover:text-theme-500">
                      Privacy Policy
                    </Link>
                  </div>
                  </div>
                )}
              </>
            )}
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-theme-500 hover:bg-theme-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500 disabled:opacity-50"
            >
              {isLoading
                ? isSignUp 
                  ? isVerifying 
                    ? 'Verifying...'
                    : 'Sending OTP...'
                  : 'Signing in...'
                : isSignUp
                  ? isVerifying
                    ? 'Verify & Sign up'
                    : 'Continue with Email'
                  : 'Sign in'
              }
            </button>
            {!isSignUp && ( // Add this condition to only show in sign-in mode
                  <div className="mt-2 text-sm text-center">
                    <Link to="/reset-password" className="text-theme-600 hover:text-theme-500">
                      Forgot Password?
                    </Link>
                  </div>
                )}
          </form>

          <div className="mt-4 text-center">
            <button
              type="button"
              onClick={toggleMode}
              className="font-medium text-theme-600 hover:text-theme-500"
            >
              {isSignUp ? 'Already have an account? Sign in' : 'Need an account? Sign up'}
            </button>
          </div>
        </div>
      </div>

      <OnboardingModal 
        isOpen={showOnboarding}
        onClose={() => {
          setShowOnboarding(false);
          navigate('/chat');
        }}
        isDarkMode={false}
      />
    </>
  );
};

export default Login;