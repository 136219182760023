import React from 'react';
import { Search, Zap, ChevronDown, Moon, Sun, MessageCirclePlus, Sparkles, ImageIcon, Eraser, Bot, PanelLeft } from 'lucide-react';

const ChatHeader = ({ 
  isDarkMode, 
  searchQuery, 
  setSearchQuery, 
  selectedModel, 
  setSelectedModel,
  setIsDarkMode,
  models,
  isModelDropdownOpen,
  setIsModelDropdownOpen,
  dropdownRef,
  onNewChat,
  activeTab,
  toggleSidebar
}) => {
  return (
    <header className={`h-[50px] flex items-center ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b px-4`}>
      <div className="flex items-center justify-between w-full relative">
        <button
          onClick={toggleSidebar}
          className={`p-2 rounded-lg ${
            isDarkMode ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-100 text-gray-600'
          }`}
          title='Toggle sidebar'
        >
          <PanelLeft size={20} />
        </button>

        <div className="absolute left-1/2 -translate-x-1/2">
          <div className={`flex items-center justify-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {activeTab === 'chat' ? <Bot size={30}/> : <ImageIcon size={30}/>}
          </div>
        </div>

        <div className="invisible">
          <div className={`flex items-center justify-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {activeTab === 'chat' ? <Bot size={30}/> : <ImageIcon size={30}/>}
          </div>
        </div>
        
        <div className="flex items-center space-x-4">
          <div className="relative group">
            <div className={`flex items-center overflow-hidden transition-all duration-300 ${
              isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-100 hover:bg-gray-200'
            } rounded-lg group-hover:w-64 w-10 h-10`}>
              <Search size={18} className={`absolute left-2.5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
              <input
                type="text"
                placeholder="Search in chat..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`pl-10 pr-4 py-2 w-full h-full opacity-100 transition-opacity duration-300 ${
                  isDarkMode ? 'bg-gray-700 text-white placeholder-gray-400' : 'bg-gray-100 text-gray-900 placeholder-gray-500'
                } focus:outline-none border-none`}
              />
            </div>
          </div>

          {activeTab === 'chat' && (
            <>
              <button
                onClick={onNewChat}
                className={`p-2 rounded-lg ${
                  isDarkMode ? 'bg-gray-700 text-gray-400 hover:bg-gray-500' : 'bg-gray-100 text-gray-500 hover:bg-gray-200'
                }`}
                title="Start new chat"
              >
                <MessageCirclePlus size={20} />
              </button>

              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsModelDropdownOpen(!isModelDropdownOpen)}
                  className={`flex items-center space-x-2 px-4 py-2 rounded-lg ${
                    isDarkMode ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                  }`}
                >
                  <Zap size={16} />
                  <span>{selectedModel}</span>
                  <ChevronDown size={16} />
                </button>

                {isModelDropdownOpen && (
                <div className={`fixed right-0 mt-2 w-80 rounded-lg shadow-lg z-50 ${
                  isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-200'
                } border`}
                style={{
                  top: '60px', // Position below header
                  right: '20px' // Adjust right spacing
                }}>
                    {models.map((model) => (
                      <button
                        key={model.id}
                        onClick={() => {
                          setSelectedModel(model.name);
                          setIsModelDropdownOpen(false);
                        }}
                        className={`flex items-center justify-between w-full px-4 py-2 text-left ${
                          isDarkMode ? 'text-white hover:bg-gray-600' : 'text-gray-900 hover:bg-gray-100'
                        } ${selectedModel === model.name ? isDarkMode ? 'bg-gray-600' : 'bg-gray-100' : ''}`}
                      >
                        <div className="flex items-center space-x-2">
                          {model.icon}
                          <div>
                            <span>{model.name}</span>
                            <span className={`block text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                              {model.description}
                            </span>
                          </div>
                        </div>
                        <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                          {model.credits} {model.credits === 1 ? 'credit' : 'credits'}/query
                        </span>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}

          {activeTab === 'image' && (
            <>
              <button
                onClick={onNewChat}
                className={`p-2 rounded-lg relative ${
                  isDarkMode ? 'bg-gray-700 text-gray-400 hover:bg-gray-500' : 'bg-gray-100 text-gray-500 hover:bg-gray-200'
                }`}
                title="Clear current chat"
              >
                <Eraser size={20} />
              </button>

              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsModelDropdownOpen(!isModelDropdownOpen)}
                  className={`flex items-center space-x-2 px-4 py-2 rounded-lg ${
                    isDarkMode ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                  }`}
                >
                  <Sparkles size={16} />
                  <span>{selectedModel}</span>
                  <ChevronDown size={16} />
                </button>

                {isModelDropdownOpen && (
                <div className={`fixed right-0 mt-2 w-80 rounded-lg shadow-lg z-50 ${
                  isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-200'
                } border`}
                style={{
                  top: '60px', // Position below header
                  right: '20px' // Adjust right spacing
                }}>
                    {models.map((model) => (
                      <button
                        key={model.id}
                        onClick={() => {
                          setSelectedModel(model.name);
                          setIsModelDropdownOpen(false);
                        }}
                        className={`flex items-center justify-between w-full px-4 py-2 text-left ${
                          isDarkMode ? 'text-white hover:bg-gray-600' : 'text-gray-900 hover:bg-gray-100'
                        } ${selectedModel === model.name ? isDarkMode ? 'bg-gray-600' : 'bg-gray-100' : ''}`}
                      >
                        <div className="flex items-center space-x-2">
                          {model.icon}
                          <span>{model.name}</span>
                        </div>
                        <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                          {model.credits} credit/image
                        </span>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}

          <button
            onClick={() => setIsDarkMode(!isDarkMode)}
            className={`p-2 rounded-lg ${
              isDarkMode ? 'bg-gray-700 text-theme-500 hover:bg-gray-600' : 'bg-gray-100 text-theme-500 hover:bg-gray-200'
            }`}
            title="Toggle theme"
          >
            {isDarkMode ? <Moon size={20} /> : <Sun size={20} />}
          </button>
        </div>
      </div>
    </header>
  );
};

export default ChatHeader;