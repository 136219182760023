import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, _setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Enhanced setCurrentUser with token handling
  const setCurrentUser = (userData) => {
    if (userData) {
      // Store auth token if provided by backend
      const token = userData.token || '';
      localStorage.setItem('authToken', token);
      
      // Set token in axios defaults
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Set session timeout (e.g., 24 hours)
      const expiryTime = new Date().getTime() + (24 * 60 * 60 * 1000);
      localStorage.setItem('sessionExpiry', expiryTime);
    }
    
    _setCurrentUser(userData);
    localStorage.setItem('currentUser', JSON.stringify(userData));
  };

  // Check session validity
  const checkSession = () => {
    const expiryTime = localStorage.getItem('sessionExpiry');
    if (expiryTime && new Date().getTime() > parseInt(expiryTime)) {
      logout();
      return false;
    }
    return true;
  };

  axios.defaults.baseURL = 'https://flexflow.in';  /* When deploying, switch to : 'https://flexflow.in', on local : 'http://localhost:8000' */

  // Add axios interceptor for 401 responses
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const initializeAuth = () => {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      const token = localStorage.getItem('authToken');
      
      if (user && token && checkSession()) {
        _setCurrentUser(user);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        logout();
      }
      setLoading(false);
    };

    initializeAuth();
    
    // Set up session check interval
    const sessionCheckInterval = setInterval(checkSession, 60000); // Check every minute
    
    return () => clearInterval(sessionCheckInterval);
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post('/auth/login', { email, password });
      setCurrentUser(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.detail || 'Failed to login');
    }
  };

  const signup = async (name, email, password) => {
    try {
      const response = await axios.post('/auth/signup', { name, email, password });
      setCurrentUser(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.detail || error.response?.data?.message || 'Failed to sign up');
    }
  };

  const logout = () => {
    _setCurrentUser(null);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('chat_history');
    localStorage.removeItem('authToken');
    localStorage.removeItem('sessionExpiry');
    delete axios.defaults.headers.common['Authorization'];
  };
  
  return (
    <AuthContext.Provider value={{ 
      currentUser, 
      setCurrentUser,
      login, 
      signup, 
      logout,
      isAuthenticated: !!currentUser
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};