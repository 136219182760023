// frontend/src/components/Landing.jsx

import React from 'react';
import { isMobileDevice } from '../utils/deviceDetection';
import DesktopLanding from './DesktopLanding';
import MobileLanding from './mobile/MobileLanding';

const Landing = () => {
  const isMobile = isMobileDevice();
  return isMobile ? <MobileLanding /> : <DesktopLanding />;
};

export default Landing;