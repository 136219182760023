import React from 'react';
import { LegalPage, List, Paragraph, Section } from './LegalPage';

const TermsOfUse = () => {
  const sections = [
    {
      title: "1. Service Description",
      content: (
        <Section>
          <Paragraph>
            FlexFlow AI provides a platform for accessing and interacting with various AI language 
            and image generation models through a credit-based system. Our service includes:
          </Paragraph>
          <List items={[
            "Text-based AI model interactions and chat functionality",
            "Image generation from text descriptions",
            "Credit management and transaction system",
            "Content storage and history management",
            "User profile and preference management"
          ]} />
        </Section>
      )
    },
    {
      title: "2. Account Terms",
      content: (
        <Section>
          <Paragraph>
            To use our services, you must:
          </Paragraph>
          <List items={[
            "Be at least 13 years of age",
            "Provide accurate and complete registration information",
            "Maintain the security of your account credentials",
            "Accept responsibility for all activities under your account",
            "Notify us immediately of any unauthorized access",
            "Not share your account credentials with others",
            "Not create multiple accounts for malicious purposes"
          ]} />
        </Section>
      )
    },
    {
      title: "3. Credits and Payments",
      content: (
        <Section>
          <h3 className="text-lg font-medium text-white mb-2">Credit System</h3>
          <List items={[
            "New users receive 100 complimentary credits upon registration",
            "Additional credits can be purchased through our platform",
            "Credits are non-refundable and non-transferable",
            "Unused credits do not expire",
            "Credit costs may vary by model and usage type"
          ]} />

          <h3 className="text-lg font-medium text-white mb-2 mt-4">Payment Terms</h3>
          <List items={[
            "All payments are processed securely through Razorpay",
            "Prices are listed in Indian Rupees (INR)",
            "Payment information is encrypted and securely stored",
            "Refunds are handled on a case-by-case basis",
            "Fraudulent transactions will result in account termination"
          ]} />
        </Section>
      )
    },
    {
      title: "4. Acceptable Use",
      content: (
        <Section>
          <Paragraph>
            You agree not to use the service to:
          </Paragraph>
          <List items={[
            "Generate or distribute harmful, illegal, or inappropriate content",
            "Violate any laws or third-party rights",
            "Attempt to circumvent service limitations or restrictions",
            "Reverse engineer or attempt to extract the underlying models",
            "Conduct automated scraping or bulk operations",
            "Interfere with other users' access to the service",
            "Engage in activities that may harm our infrastructure"
          ]} />
        </Section>
      )
    },
    {
      title: "5. Content Guidelines",
      content: (
        <Section>
          <Paragraph>
            All content must comply with our guidelines:
          </Paragraph>
          <List items={[
            "No hate speech or discriminatory content",
            "No explicit or adult content",
            "No violence or graphic content",
            "No harassment or bullying",
            "No misinformation or deliberate fake news",
            "No copyright or trademark infringement",
            "No illegal or regulated goods/services promotion"
          ]} />
        </Section>
      )
    },
    {
      title: "6. Intellectual Property",
      content: (
        <Section>
          <List items={[
            "You retain rights to content you create using our service",
            "Generated content cannot be used to train competing AI models",
            "Our service interface and technology remain our property",
            "You grant us license to process and store your content",
            "Third-party model rights are retained by their owners",
            "Feedback and suggestions become our property",
            "Brand assets and trademarks are protected"
          ]} />
        </Section>
      )
    },
    {
      title: "7. Service Availability",
      content: (
        <Section>
          <Paragraph>
            While we strive for high availability:
          </Paragraph>
          <List items={[
            "Service may be interrupted for maintenance or updates",
            "No guarantee of uninterrupted or error-free service",
            "We reserve the right to modify or discontinue features",
            "Advanced notice will be given for scheduled maintenance",
            "No compensation for temporary service interruptions"
          ]} />
        </Section>
      )
    },
    {
      title: "8. Privacy and Data Security",
      content: (
        <Section>
          <List items={[
            "We follow industry standard security practices",
            "User data is protected as per our Privacy Policy",
            "We may collect usage data for service improvement",
            "Third-party services follow our data protection requirements",
            "Users are responsible for their content security"
          ]} />
        </Section>
      )
    },
    {
      title: "9. Termination",
      content: (
        <Section>
          <Paragraph>
            We reserve the right to suspend or terminate accounts that:
          </Paragraph>
          <List items={[
            "Violate these terms or our content guidelines",
            "Engage in fraudulent activity",
            "Abuse the service or other users",
            "Fail to pay for services",
            "Create multiple accounts for malicious purposes"
          ]} />
        </Section>
      )
    },
    {
      title: "10. Liability and Disclaimers",
      content: (
        <Section>
          <List items={[
            "Service is provided 'as is' without warranties",
            "We are not liable for generated content accuracy",
            "Users are responsible for their use of the service",
            "We do not guarantee specific results or outcomes",
            "Limitation of liability to amount paid for service"
          ]} />
        </Section>
      )
    },
    {
      title: "11. Changes to Terms",
      content: (
        <Section>
          <Paragraph>
            We may update these terms:
          </Paragraph>
          <List items={[
            "Changes will be announced via email or in-app notification",
            "Continued use implies acceptance of new terms",
            "Major changes will require explicit acceptance",
            "Users may terminate service if they disagree with changes",
            "Previous versions will be archived and available"
          ]} />
        </Section>
      )
    }
  ];

  return <LegalPage title="Terms of Use" sections={sections} />;
};

export default TermsOfUse;