import React, { useState } from 'react';
import { MessageSquare, Copy, Check, Download, X, Link as LinkIcon } from 'lucide-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark, oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { parseMarkdownTable } from '../../utils/tableParser';
import Table from '../Table';

const ImagePreview = ({ src, onClose, isDarkMode }) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75" onClick={onClose}>
    <button 
      className={`absolute top-4 right-4 p-2 rounded-full ${
        isDarkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-gray-100'
      }`}
      onClick={onClose}
    >
      <X size={24} className="text-gray-500" />
    </button>
    <img 
      src={src} 
      alt="Preview" 
      className="max-w-[90vw] max-h-[90vh] object-contain"
      onClick={e => e.stopPropagation()}
    />
  </div>
);

const MessagesLoader = ({ isDarkMode }) => (
  <div className="flex items-center justify-center py-2">
    <div className="flex space-x-1.5">
      {[...Array(3)].map((_, i) => (
        <div
          key={i}
          className={`w-2 h-2 rounded-full ${
            isDarkMode ? 'bg-theme-500' : 'bg-theme-500'
          } animate-bounce`}
          style={{
            animationDelay: `${i * 0.15}s`,
            animationDuration: '0.8s'
          }}
        />
      ))}
    </div>
  </div>
);
const downloadImage = (base64Data, filename = 'generated-image.png') => {
  const link = document.createElement('a');
  link.href = base64Data;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const detectLanguage = (code) => {
  // Extract language from code fence if present
  const languageMatch = code.match(/^```(\w+)/);
  if (languageMatch && languageMatch[1]) {
    return languageMatch[1].toLowerCase();
  }

  // Remove backticks and any language identifier for content analysis
  const cleanCode = code.replace(/^```[\w]*\n|```$/gm, '').trim();

  const patterns = {
    python: {
      keywords: ['def ', 'class ', 'import ', 'from ', 'print(', 'if __name__'],
      extensions: ['.py'],
    },
    javascript: {
      keywords: ['const ', 'let ', 'var ', '=>', 'function ', 'export ', 'import '],
      extensions: ['.js', '.jsx'],
    },
    typescript: {
      keywords: ['interface ', 'type ', '<>', 'export type'],
      extensions: ['.ts', '.tsx'],
    },
    jsx: {
      keywords: ['<div>', '<span>', 'className=', 'useState', 'useEffect'],
      extensions: ['.jsx'],
    },
    html: {
      keywords: ['<!DOCTYPE', '<html>', '<div>', '<body>', '<head>'],
      extensions: ['.html'],
    },
    css: {
      keywords: ['{', 'margin:', 'padding:', 'display:', '@media'],
      extensions: ['.css'],
    },
    sql: {
      keywords: ['SELECT ', 'FROM ', 'WHERE ', 'INSERT INTO', 'CREATE TABLE'],
      extensions: ['.sql'],
    },
    java: {
      keywords: ['public class', 'private ', 'protected ', '@Override'],
      extensions: ['.java'],
    },
    shell: {
      keywords: ['#!/', 'echo ', 'sudo ', 'apt ', 'npm ', 'yarn '],
      extensions: ['.sh', '.bash'],
    },
    markdown: {
      keywords: ['# ', '## ', '- ', '* ', '> '],
      extensions: ['.md', '.markdown'],
    }
  };

  // Check file extensions in the code
  for (const [lang, pattern] of Object.entries(patterns)) {
    for (const ext of pattern.extensions) {
      if (cleanCode.includes(ext)) return lang;
    }
  }

  let maxScore = 0;
  let detectedLang = 'text';

  // Score each language based on keyword matches
  for (const [lang, pattern] of Object.entries(patterns)) {
    const score = pattern.keywords.reduce((count, keyword) => 
      count + (cleanCode.includes(keyword) ? 1 : 0), 0);

    if (score > maxScore) {
      maxScore = score;
      detectedLang = lang;
    }
  }

  // Check for JSON
  if (cleanCode.trim().startsWith('{') && cleanCode.trim().endsWith('}')) {
    try {
      JSON.parse(cleanCode);
      return 'json';
    } catch (e) {}
  }

  return maxScore > 0 ? detectedLang : 'text';
};

const CodeBlock = ({ code, isDarkMode }) => {
  const [copied, setCopied] = useState(false);
  
  // Clean the code and detect language
  const cleanCode = code.replace(/^```[\w]*\n|```$/gm, '').trim();
  const language = detectLanguage(code);
  
  const baseTheme = isDarkMode ? oneDark : oneLight;
  
  const modifiedTheme = {
    ...baseTheme,
    'pre[class*="language-"]': {
      ...baseTheme['pre[class*="language-"]'],
      background: 'transparent',
    },
    'code[class*="language-"]': {
      ...baseTheme['code[class*="language-"]'],
      background: 'transparent',
    }
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(cleanCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className={`relative mt-2 rounded-lg overflow-hidden ${
      isDarkMode ? 'bg-gray-900 border border-gray-700' : 'bg-gray-50 border border-gray-200'
    }`}>
      <div className={`flex items-center justify-between px-3 py-2 ${
        isDarkMode ? 'bg-gray-800 text-gray-300 border-b border-gray-700' 
                  : 'bg-gray-100 text-gray-600 border-b border-gray-200'
      }`}>
        <span className="text-xs font-medium">{language}</span>
        <button
          onClick={handleCopy}
          className={`p-1.5 rounded-md transition-colors ${
            isDarkMode ? 'hover:bg-gray-700 text-gray-400 hover:text-gray-200' 
                      : 'hover:bg-gray-200 text-gray-500 hover:text-gray-700'
          }`}
        >
          {copied ? <Check size={14} /> : <Copy size={14} />}
        </button>
      </div>
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        <SyntaxHighlighter
          language={language}
          style={modifiedTheme}
          customStyle={{
            margin: 0,
            padding: '1rem',
            background: 'transparent',
            fontSize: '0.9rem'
          }}
        >
          {cleanCode}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

const processLinks = (text) => {
  // Regex to match URLs with optional brackets or parentheses
  const urlRegex = /(?:\[|\()?(https?:\/\/[^\s\]\)]+)(?:\]|\))?/g;
  const urls = new Map(); // Use Map to track URLs and their positions
  let lastIndex = 0;
  let result = '';

  // First pass: collect all URLs and their positions
  let match;
  while ((match = urlRegex.exec(text)) !== null) {
    const cleanUrl = match[1]; // The captured URL without brackets/parentheses
    const position = match.index;
    
    // If we've seen this URL before and it's close to a previous occurrence
    if (urls.has(cleanUrl)) {
      const prevPosition = urls.get(cleanUrl);
      if (Math.abs(position - prevPosition) < 50) { // Increased threshold to catch more duplicates
        continue; // Skip this occurrence
      }
    }
    
    urls.set(cleanUrl, position);
  }

  // Second pass: replace URLs
  urlRegex.lastIndex = 0; // Reset regex index
  while ((match = urlRegex.exec(text)) !== null) {
    const cleanUrl = match[1];
    
    // Only process the first occurrence of each URL
    if (urls.get(cleanUrl) === match.index) {
      result += text.slice(lastIndex, match.index);
      result += `<a href="${cleanUrl}" target="_blank" rel="noopener noreferrer" class="inline-flex items-center gap-1 text-theme-500 hover:text-theme-600"><LinkIcon size={14} />${cleanUrl}</a>`;
      lastIndex = match.index + match[0].length;
    }
  }
  
  result += text.slice(lastIndex);
  return result;
};

const processContent = (text) => {
  const blocks = [];
  let currentBlock = '';
  let isInList = false;
  let listItems = [];
  let listCounter = 1;
  let isInTable = false;
  let tableContent = '';
  let isInCode = false;
  let codeContent = '';
  
  const lines = text.split('\n');
  
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();
    
    // Check for code blocks
    if (line.startsWith('```')) {
      if (!isInCode) {
        if (currentBlock) {
          blocks.push({ type: 'text', content: currentBlock });
          currentBlock = '';
        }
        isInCode = true;
        codeContent = line + '\n';
        continue;
      } else {
        codeContent += line;
        blocks.push({ type: 'code', content: codeContent });
        codeContent = '';
        isInCode = false;
        continue;
      }
    }
    
    if (isInCode) {
      codeContent += line + '\n';
      continue;
    }

    // Check for table
    if (line.includes('|')) {
      if (!isInTable) {
        if (currentBlock) {
          blocks.push({ type: 'text', content: currentBlock });
          currentBlock = '';
        }
        isInTable = true;
      }
      tableContent += line + '\n';
      continue;
    } else if (isInTable) {
      blocks.push({ type: 'table', content: tableContent.trim() });
      tableContent = '';
      isInTable = false;
    }

    // Check for numbered list items
    const listMatch = line.match(/^(\d+)\.\s+(.+)/);
    
    if (listMatch) {
      if (!isInList) {
        if (currentBlock) {
          blocks.push({ type: 'text', content: currentBlock });
          currentBlock = '';
        }
        isInList = true;
        listCounter = parseInt(listMatch[1]);
      }
      listItems.push({
        number: listCounter++,
        content: listMatch[2]
      });
      continue;
    }
    
    // End of list
    if (isInList && !listMatch) {
      blocks.push({ 
        type: 'list', 
        items: listItems.map(item => ({
          number: item.number,
          content: item.content
        }))
      });
      listItems = [];
      isInList = false;
      listCounter = 1;
    }

    // Check for headings
    if (line.startsWith('#')) {
      if (currentBlock) {
        blocks.push({ type: 'text', content: currentBlock });
        currentBlock = '';
      }
      const level = line.match(/^#+/)[0].length;
      const content = line.slice(level).trim();
      blocks.push({ type: 'heading', level, content });
      continue;
    }

    if (!isInList && !isInTable && !isInCode) {
      currentBlock += (currentBlock ? '\n' : '') + line;
    }
  }
  
  // Handle any remaining content
  if (isInCode) {
    blocks.push({ type: 'code', content: codeContent });
  } else if (isInTable) {
    blocks.push({ type: 'table', content: tableContent.trim() });
  } else if (isInList && listItems.length > 0) {
    blocks.push({ 
      type: 'list', 
      items: listItems.map(item => ({
        number: item.number,
        content: item.content
      }))
    });
  } else if (currentBlock) {
    blocks.push({ type: 'text', content: currentBlock });
  }
  
  return blocks;
};

const parseBoldText = (text) => {
  // First process links
  const processedText = processLinks(text);
  
  // Then handle bold text, but skip processing within <a> tags
  const parts = processedText.split(/(\*\*[^*]+\*\*)/g);
  return parts.map((part, index) => {
    if (part.includes('<a')) {
      return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
    }
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }
    return part;
  });
};

const NotionBlock = ({ content, isDarkMode }) => {
  if (typeof content !== 'string') {
    return (
      <div className={`prose ${isDarkMode ? 'prose-invert' : ''} max-w-none`}>
        {content}
      </div>
    );
  }

  const blocks = processContent(content);

  return (
    <div className={`prose ${isDarkMode ? 'prose-invert' : ''} max-w-none`}>
      {blocks.map((block, index) => {
        if (block.type === 'heading') {
          const HeadingTag = `h${block.level}`;
          return (
            <HeadingTag 
              key={index}
              className={`font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              } ${block.level === 1 ? 'text-2xl' : 'text-xl'} mb-4`}
            >
              {parseBoldText(block.content)}
            </HeadingTag>
          );
        }
        
        if (block.type === 'code') {
          return <CodeBlock key={index} code={block.content} isDarkMode={isDarkMode} />;
        }
        
        if (block.type === 'table') {
          const tableData = parseMarkdownTable(block.content);
          if (tableData) {
            return <Table key={index} data={tableData} isDarkMode={isDarkMode} />;
          }
          return null;
        }

        if (block.type === 'list') {
          return (
            <ol key={index} className="list-decimal list-outside ml-6">
              {block.items.map((item, i) => (
                <li key={i} value={item.number} className={`my-1 text-base leading-7 ${
                  isDarkMode ? 'text-gray-200' : 'text-gray-700'
                }`}>
                  {parseBoldText(item.content)}
                </li>
              ))}
            </ol>
          );
        }
        
        return (
          <div key={index} className="my-4">
            {block.content.split('\n').map((line, i) => (
              <p key={i} className={`my-2 text-base leading-7 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                {parseBoldText(line)}
              </p>
            ))}
          </div>
        );
      })}
    </div>
  );
};

const ChatMessages = ({ messages, searchQuery, isDarkMode, isTyping, messagesEndRef }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [copiedMessageId, setCopiedMessageId] = useState(null);

  if (!messages.length) {
    return <EmptyChat isDarkMode={isDarkMode} />;
  }

  const handleCopyMessage = async (text) => {
    await navigator.clipboard.writeText(text);
    setCopiedMessageId(text);
    setTimeout(() => setCopiedMessageId(null), 2000);
  };

  const highlightText = (text, searchTerm) => {
    if (!text || !searchTerm) return text;
    
    return text.split(new RegExp(`(${searchTerm})`, 'gi')).map((part, index) => 
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} className={`bg-yellow-300 ${
          isDarkMode ? 'text-gray-900' : 'text-gray-900'
        } px-1 rounded`}>
          {part}
        </span>
      ) : part
    );
  };

 
  return (
    <div className="max-w-4xl mx-auto space-y-4">
      {messages.map((msg) => (
        msg.sender === 'user' ? (
          <div key={msg.id} className="flex justify-end mb-1 group">
            <div className="max-w-[70%] bg-theme-500 text-white rounded-xl px-3 py-2 shadow-sm 
              transition-all duration-200 ease-in-out hover:shadow-md hover:bg-theme-600">
              <p className="text-[15px] leading-relaxed">{highlightText(msg.text, searchQuery)}</p>
            </div>
          </div>
        ) : (
          <div key={msg.id} className={`w-full group relative transition-all duration-200 ease-in-out ${
            isDarkMode 
              ? 'hover:bg-gray-800/70 bg-gray-800/40' 
              : 'hover:bg-gray-100 bg-gray-50'
          } rounded-xl px-4 py-3 shadow-sm hover:shadow-md`}>
            <button
              onClick={() => handleCopyMessage(msg.text)}
              className={`absolute right-2 bottom-2 p-2 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity ${
                isDarkMode ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-200 text-gray-600'
              }`}
              title="Copy message"
            >
              {copiedMessageId === msg.text ? <Check size={16} /> : <Copy size={16} />}
            </button>
            {msg.isImage ? (
              <div className="flex flex-col items-center space-y-4 mx-auto">
                <div className="relative w-full flex justify-center">
                  <img 
                    src={msg.text} 
                    alt="Generated" 
                    className="max-w-[512px] w-full h-auto rounded-xl transition-transform duration-300 hover:scale-[1.01]"
                    onClick={() => setPreviewImage(msg.text)}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'placeholder-image-url';
                    }}
                  />
                </div>
                <button
                  onClick={() => downloadImage(msg.text)}
                  className={`flex items-center gap-2 px-4 py-2 rounded-xl transition-all duration-200 ${
                    isDarkMode 
                      ? 'bg-theme-500 hover:bg-theme-600 text-white' 
                      : 'bg-theme-500 hover:bg-theme-600 text-white'
                  }`}
                >
                  <Download size={18} />
                  Download Image
                </button>
              </div>
            ) : (
              <NotionBlock 
                content={searchQuery ? highlightText(msg.text, searchQuery) : msg.text} 
                isDarkMode={isDarkMode} 
              />
            )}
          </div>
        )
      ))}
      {isTyping && <MessagesLoader isDarkMode={isDarkMode} />}
      {previewImage && (
        <ImagePreview 
          src={previewImage} 
          onClose={() => setPreviewImage(null)} 
          isDarkMode={isDarkMode}
        />
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

const EmptyChat = ({ isDarkMode }) => (
  <div className="h-full flex flex-col items-center justify-center">
    <div className={`p-4 rounded-full ${
      isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
    } mb-4`}>
      <MessageSquare size={32} className="text-theme-500" />
    </div>
    <h2 className={`text-xl font-bold mb-2 ${
      isDarkMode ? 'text-white' : 'text-gray-900'
    }`}>
      Start a conversation
    </h2>
    <p className={`text-center ${
      isDarkMode ? 'text-gray-400' : 'text-gray-600'
    }`}>
      Choose a model and start chatting
    </p>
  </div>
);

export default ChatMessages;