import React, { useState } from 'react';
import { Download, Copy, Check } from 'lucide-react';

const Table = ({ data, isDarkMode }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    const tsvContent = [
      data.headers.join('\t'),
      ...data.rows.map(row => row.join('\t'))
    ].join('\n');
    
    await navigator.clipboard.writeText(tsvContent);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleDownload = () => {
    const csvContent = [
      data.headers.join(','),
      ...data.rows.map(row => row.join(','))
    ].join('\n');
    
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'table-data.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="mt-4 space-y-1">
      <div className={`rounded-xl overflow-hidden border ${
        isDarkMode ? 'border-gray-700 bg-gray-800/40' : 'border-gray-200 bg-gray-50'
      }`}>
        <table className="w-full">
          <thead>
            <tr className={
              isDarkMode ? 'bg-gray-800 text-gray-200' : 'bg-white text-gray-900'
            }>
              {data.headers.map((header, i) => (
                <th key={i} className={`px-6 py-4 text-left text-sm font-medium ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
            {data.rows.map((row, i) => (
              <tr key={i} className={`
                border-t
                ${isDarkMode 
                  ? 'border-gray-700 hover:bg-gray-700/50' 
                  : 'border-gray-200 hover:bg-gray-100'
                }
              `}>
                {row.map((cell, j) => (
                  <td key={j} className="px-6 py-4 text-sm whitespace-nowrap">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end gap-1">
        <button
          onClick={handleCopy}
          className={`p-1 rounded transition-colors ${
            isDarkMode 
              ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-300' 
              : 'hover:bg-gray-100 text-gray-400 hover:text-gray-600'
          }`}
          title='Copy table data'
        >
          {copied ? <Check size={16} /> : <Copy size={16} />}
        </button>
        <button
          onClick={handleDownload}
          className={`p-1 rounded transition-colors ${
            isDarkMode 
              ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-300' 
              : 'hover:bg-gray-100 text-gray-400 hover:text-gray-600'
          }`}
          title='Download table data'
        >
          <Download size={16} />
        </button>
      </div>
    </div>
  );
};

export default Table;