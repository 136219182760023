import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useEvent } from '../../contexts/EventContext';
import axios from 'axios';
import MobileHeader from './MobileHeader';
import ChatMessages from '../chat/ChatMessages';
import MobileChatInput from './MobileChatInput';
import MobileSidebar from './MobileSidebar';
import { Menu, Zap, Sparkles } from 'lucide-react';
import flowLogo from '../../assets/flexflowai_logo.png';
import OnboardingModal from '../modals/OnboardingModal';

const MobileChatInterface = () => {
  const { currentUser, logout } = useAuth();
  const { triggerCreditUpdate } = useEvent();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const sidebarRef = useRef(null);
  const [showOnboarding, setShowOnboarding] = useState(false);
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(() => localStorage.getItem('active_tab') || 'chat');
  const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('theme') === 'dark');
  const [chatMessages, setChatMessages] = useState(() => {
    const saved = localStorage.getItem('chat_messages');
    return saved ? JSON.parse(saved) : { chat: [], image: [] };
  });
  const [message, setMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedModel, setSelectedModel] = useState(() => 
    localStorage.getItem('selected_model') || 'deepseek-v3'
  );
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [error, setError] = useState(null);

  const models = [
    { id: 1, name: 'deepseek-v3', icon: <Zap size={16} />, credits: 1,description: 'Best at Data Analysis' },
    { id: 2, name: 'llama-3-8b-instruct', icon: <Zap size={16} />, credits: 1,description: 'Best at How-To-Guides' },
    { id: 3, name: 'gpt-4o-mini', icon: <Zap size={16} />, credits: 2,description: 'Best at General Chat' },
    { id: 4, name: 'claude-3.5-haiku', icon: <Zap size={16} />, credits: 2,description: 'Best at Creative Writing' },
    { id: 5, name: 'gpt-4o', icon: <Zap size={16} />, credits: 4,description: 'Best at Summarization' },
    { id: 6, name: 'claude-3.5-sonnet', icon: <Zap size={16} />, credits: 4,description: 'Best at Coding' },
    { id: 7, name: 'deepseek-r1', icon: <Zap size={16} />, credits: 2,description: 'Best at Research' },
  ];

  const imageModels = [
    { id: 1, name: 'flux-schnell', icon: <Sparkles size={16} />, credits: 1,description: 'Animated Images' },
    { id: 2, name: 'sticker-maker', icon: <Sparkles size={16} />, credits: 1,description: 'Sticker Creation' },
    { id: 3, name: 'sdxl-lightning-4step', icon: <Sparkles size={16} />, credits: 1,description: 'Semi Animated Images' }
  ];

  useEffect(() => {
    localStorage.setItem('chat_messages', JSON.stringify(chatMessages));
  }, [chatMessages]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('chat_messages');
    localStorage.removeItem('active_tab');
    localStorage.removeItem('selected_model');
    logout();
    navigate('/login');
  }, [logout, navigate]);

  const loadChatHistory = async (chatId) => {
    try {
      setSelectedChatId(chatId);
      const response = await axios.get(`/api/chat/messages/${chatId}`, {
        params: { user_id: currentUser.id }
      });
      
      const formattedMessages = response.data.flatMap(msg => [
        {
          id: `${msg._id}-user`,
          text: msg.content,
          sender: 'user',
          timestamp: msg.timestamp
        },
        {
          id: `${msg._id}-bot`,
          text: msg.response,
          sender: 'bot',
          timestamp: msg.timestamp
        }
      ]);

      setChatMessages(prev => ({
        ...prev,
        [activeTab]: formattedMessages
      }));
      setError(null);
      setIsSidebarOpen(false);
      
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } catch (error) {
      setError('Failed to load chat history');
    }
  };

  const handleNewChat = useCallback(() => {
    setSelectedChatId(null);
    setChatMessages(prev => ({
      ...prev,
      [activeTab]: []
    }));
    setError(null);
    setIsSidebarOpen(false);
    sidebarRef.current?.fetchChatHistory();
  }, [activeTab]);

  const handleSubmit = async (e, fileData, tokenCount) => {
    e.preventDefault();
    if (!message.trim()) return;

    const currentSessionId = selectedChatId || new Date().getTime().toString();
    const userMessage = {
      id: Date.now(),
      text: message.trim(),
      sender: 'user',
      timestamp: new Date().toISOString(),
      model: selectedModel,
      session_id: currentSessionId,
      token_count: tokenCount
    };

    setChatMessages(prev => ({
      ...prev,
      [activeTab]: [...prev[activeTab], userMessage]
    }));
    setMessage('');
    setIsTyping(true);
    setError(null);

    try {
      const endpoint = activeTab === 'image' ? '/api/generate' : '/api/chat';
      const payload = activeTab === 'image' ? {
        prompt: userMessage.text,
        negative_prompt: "never show explicit content",
        user_id: currentUser.id,
        model: selectedModel
      } : {
        content: userMessage.text,
        model: selectedModel,
        user_id: currentUser.id,
        token_count: tokenCount,
        context: chatMessages[activeTab].slice(-5).map(msg => ({
          content: msg.text,
          response: msg.sender === 'bot' ? msg.text : '',
          role: msg.sender
        })),
        session_id: currentSessionId,
        file_content: fileData
      };

      const response = await axios.post(endpoint, payload);
      
      const botMessage = {
        id: Date.now() + 1,
        text: activeTab === 'image' ? response.data.image_url : response.data.content,
        sender: 'bot',
        timestamp: new Date().toISOString(),
        model: selectedModel,
        session_id: currentSessionId,
        isImage: activeTab === 'image'
      };

      setChatMessages(prev => ({
        ...prev,
        [activeTab]: [...prev[activeTab], botMessage]
      }));
      setSelectedChatId(currentSessionId);
      triggerCreditUpdate();
      sidebarRef.current?.fetchChatHistory();

    } catch (error) {
      if (error.response?.status === 402) {
        setError('Insufficient credits. Please purchase more credits to continue.');
      } else if (error.response?.status === 400 && error.response?.data?.detail?.includes('flagged')) {
        setError(`Content flagged by moderation. Please revise and try again.`);
      } else {
        setError(error.response?.data?.detail || 'Failed to send message');
      }
      setChatMessages(prev => ({
        ...prev,
        [activeTab]: prev[activeTab].filter(msg => msg.id !== userMessage.id)
      }));
    } finally {
      setIsTyping(false);
    }
  };

  return (
    <div className="h-screen w-screen bg-gray-100 dark:bg-gray-950 overflow-hidden">
      {/* Mobile Header */}
      <MobileHeader 
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        models={activeTab === 'image' ? imageModels : models}
        onNewChat={handleNewChat}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
      />

      {/* Sliding Sidebar */}
      <div 
        className={`fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ${
          isSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsSidebarOpen(false)}
      >
        <div 
          className={`absolute left-0 top-0 h-full w-80 transform transition-transform duration-300 ${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
          onClick={e => e.stopPropagation()}
        >
          <MobileSidebar
            ref={sidebarRef}
            currentUser={currentUser}
            isDarkMode={isDarkMode}
            handleLogout={handleLogout}
            flowLogo={flowLogo}
            onChatSelect={loadChatHistory}
            selectedChatId={selectedChatId}
            onNewChat={handleNewChat}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>

      {/* Main Chat Area */}
      <div className={`h-[calc(100vh-8rem)] overflow-y-auto pb-4 px-2 ${
        isDarkMode ? 'bg-gray-900' : 'bg-gray-50'
      }`}>
        {error && (
          <div className="mx-auto mb-4 px-2">
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-xl relative">
              <span className="block sm:inline">{error}</span>
            </div>
          </div>
        )}
        
        <ChatMessages
          messages={searchQuery 
            ? chatMessages[activeTab].filter(msg => 
                msg.text.toLowerCase().includes(searchQuery.toLowerCase())
              )
            : chatMessages[activeTab]}
          searchQuery={searchQuery}
          isDarkMode={isDarkMode}
          isTyping={isTyping}
          messagesEndRef={messagesEndRef}
          isImageMode={activeTab === 'image'}
        />
      </div>

      {/* Chat Input */}
      <div className={`fixed bottom-0 left-0 right-0 border-t ${
        isDarkMode 
          ? 'bg-gray-900 border-gray-700'
          : 'bg-white border-gray-200'
      }`}>
        <MobileChatInput
          message={message}
          setMessage={setMessage}
          handleSubmit={handleSubmit}
          isDarkMode={isDarkMode}
          activeTab={activeTab}
          error={error}
          selectedModel={selectedModel}
          selectedChatId={selectedChatId}
          setShowOnboarding={setShowOnboarding} 
        />
        <OnboardingModal 
          isOpen={showOnboarding}
          onClose={() => setShowOnboarding(false)}
          isDarkMode={isDarkMode}
        />
      </div>
    </div>
  );
};

export default MobileChatInterface;