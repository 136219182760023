export const parseMarkdownTable = (text) => {
  try {
    // Split into lines and filter empty lines
    const lines = text.split('\n').filter(line => line.trim());
    if (lines.length < 3) return null; // Need header, separator, and at least one row

    // Check if each line has pipe characters
    if (!lines.every(line => line.includes('|'))) return null;

    // Clean and split lines into cells
    const rows = lines.map(line => 
      line.trim()
        .replace(/^\||\|$/g, '') // Remove leading/trailing pipes
        .split('|')
        .map(cell => cell.trim())
        .filter(cell => cell.length > 0)
    );

    // Validate separator line (second line)
    const isSeparator = rows[1].every(cell => /^[-:]+$/.test(cell));
    if (!isSeparator) return null;

    // Get headers and data rows
    const headers = rows[0].map(header => header.replace(/\*\*/g, ''));
    const dataRows = rows.slice(2);

    // Validate structure (all rows should have same number of columns)
    const columnCount = headers.length;
    if (!dataRows.every(row => row.length === columnCount)) return null;

    return {
      headers,
      rows: dataRows
    };
  } catch (error) {
    console.error('Error parsing table:', error);
    return null;
  }
};