import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Plus } from 'lucide-react';
import PurchaseCreditsModal from './modals/PurchaseCreditsModal';

const CreditsManager = ({ isDarkMode }) => {
  const { currentUser } = useAuth();
  const [creditStatus, setCreditStatus] = useState(null);
  const [showWeeklyData, setShowWeeklyData] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);

  const fetchCreditStatus = async () => {
    try {
      const [creditResponse, transactionResponse] = await Promise.all([
        axios.get(`/api/credits/status/${currentUser.id}`),
        axios.get(`/auth/user/transactions/${currentUser.id}?limit=50`)
      ]);

      const today = new Date();
      const last7Days = Array(7).fill().map((_, i) => {
        const date = new Date(today);
        date.setDate(today.getDate() - (6 - i));
        return date.toISOString().split('T')[0];
      });

      const dailyUsage = last7Days.map(date => {
        const dayTransactions = transactionResponse.data.filter(t => {
          const transactionDate = new Date(t.timestamp).toISOString().split('T')[0];
          return transactionDate === date && t.type === 'spent';
        });

        return {
          date: new Date(date),
          day: new Date(date).toLocaleDateString('en-US', { weekday: 'short' }),
          usage: dayTransactions.reduce((sum, t) => sum + t.amount, 0)
        };
      });

      setCreditStatus({
        total_credits: creditResponse.data.total_credits,
        used_credits: creditResponse.data.used_credits,
        daily_usage: dailyUsage
      });
      setTransactions(transactionResponse.data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePurchaseCredits = async (amount) => {
    try {
      await axios.post(`/auth/user/purchase-credits/${currentUser.id}`, {
        amount
      });
      fetchCreditStatus();
    } catch (error) {
      console.error('Error purchasing credits:', error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchCreditStatus();
      const handleCreditUpdate = () => fetchCreditStatus();
      window.addEventListener('updateCredits', handleCreditUpdate);
      
      return () => {
        window.removeEventListener('updateCredits', handleCreditUpdate);
      };
    }
  }, [currentUser]);

  if (!creditStatus) return null;

  const usagePercentage = (creditStatus.used_credits / creditStatus.total_credits) * 100;
  const showPurchaseButton = usagePercentage >= 0;

  return (
    <>
      <div className="flex flex-col items-center justify-center py-2">
        <div className="relative">
          <div 
            className={`absolute bottom-full left-0 w-56 bg-theme-500 rounded-lg p-3 mb-2 shadow-lg transform transition-all duration-300 ease-in-out origin-bottom 
              ${showWeeklyData ? 'opacity-100 translate-y-0 scale-y-100' : 'opacity-0 translate-y-4 scale-y-0'}`}
          >
            <div className="text-xs text-white mb-2">Last 7 Days Usage</div>
            <div className="space-y-4">
              <div className="flex items-end justify-between gap-2 h-16">
                {creditStatus.daily_usage.map((data, index) => (
                  <div key={index} className="w-3 flex flex-col items-center">
                    <div className="text-xs text-white mb-1">{data.usage}</div>
                    <div 
                      className="w-full bg-white/80 rounded-full transition-all duration-300 hover:bg-white"
                      style={{ 
                        height: `${(data.usage / Math.max(...creditStatus.daily_usage.map(d => d.usage), 1)) * 64}px`,
                        minHeight: '8px'
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="flex justify-between text-xs text-white/80">
                {creditStatus.daily_usage.map((data, index) => (
                  <div key={index} className="w-3 text-center">
                    {data.day.slice(0, 3)}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="relative">
            <div 
              className="bg-theme-500 p-3 rounded-2xl text-white w-56 transition-shadow duration-300 hover:shadow-lg"
              onMouseEnter={() => setShowWeeklyData(true)}
              onMouseLeave={() => setShowWeeklyData(false)}
            >
              <h3 className="text-lg font-medium mb-2">Credits</h3>
              <div className="mb-4">
                <div className="w-full bg-white/20 rounded-full h-1.5 mb-1.5">
                  <div
                    className="rounded-full h-1.5 bg-white transition-all duration-300"
                    style={{ width: `${usagePercentage}%` }}
                  />
                </div>
                <div className="text-xs">
                  {creditStatus.used_credits}/{creditStatus.total_credits} credits used
                </div>
              </div>
            </div>
            {showPurchaseButton && (
              <button
                onClick={() => setShowPurchaseModal(true)}
                className="mt-2 w-full flex items-center justify-center gap-1 text-sm group"
              >
                <Plus size={14} className="text-theme-500 group-hover:text-theme-400" />
                <span className="text-theme-500 font-semibold group-hover:text-theme-400">Purchase Credits</span>
              </button>
            )}
          </div>
        </div>
      </div>

      <PurchaseCreditsModal
        isOpen={showPurchaseModal}
        onClose={() => setShowPurchaseModal(false)}
        onPurchase={handlePurchaseCredits}
        isDarkMode={isDarkMode}
      />
    </>
  );
};

export default CreditsManager;