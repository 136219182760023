import React from 'react';
import { motion } from 'framer-motion';
import { Brain } from 'lucide-react';
import { isMobileDevice } from '../utils/deviceDetection';

const BenchmarkSection = () => {
  const isMobile = isMobileDevice();

  const benchmarkData = {
    categories: [
      {
        name: "English Benchmarks",
        metrics: [
          ["MMLU (EM)", "88.5", "88.6", "88.3", "87.2"],
          ["MMLU-Redux (EM)", "89.1", "86.2", "88.9", "88.0"],
          ["MMLU-Pro (EM)", "75.9", "73.3", "78.0", "72.6"],
          ["DROP (3-shot F1)", "91.6", "88.7", "88.3", "83.7"],
          ["IF-Eval (Prompt Strict)", "86.1", "86.0", "86.5", "84.3"]
        ]
      },
      {
        name: "Code Benchmarks",
        metrics: [
          ["HumanEval-Mul (Pass@1)", "82.6", "77.2", "81.7", "80.5"],
          ["LiveCodeBench (Pass@1-COT)", "40.5", "28.4", "36.3", "33.4"],
          ["LiveCodeBench (Pass@1)", "37.6", "30.1", "32.8", "34.2"],
          ["Codeforces (Percentile)", "51.6", "25.3", "20.3", "23.6"]
        ]
      },
      {
        name: "Math Benchmarks",
        metrics: [
          ["AIME 2024 (Pass@1)", "39.2", "23.3", "16.0", "9.3"],
          ["MATH-500 (EM)", "90.2", "73.8", "78.3", "74.6"],
          ["CNMO 2024 (Pass@1)", "43.2", "6.8", "13.1", "10.8"]
        ]
      }
    ],
    models: ["DeepSeek-V3", "Llama3.1-405B", "Claude-3.5", "GPT-4o"]
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-800 via-gray-900 to-gray-800 py-12 md:py-20">
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-8 md:mb-16"
        >
          <div className="flex items-center justify-center gap-3 mb-6">
            <div className="p-3 rounded-lg bg-theme-500/20 border border-theme-500/30">
              <Brain className="text-theme-500" size={24} />
            </div>
          </div>
          <h2 className="text-2xl md:text-4xl font-light mb-4 bg-gradient-to-r from-white via-theme-400 to-theme-600 bg-clip-text text-transparent">
            Our Current Favourite Model
          </h2>
          <p className="text-base md:text-xl text-gray-400 max-w-2xl mx-auto">
            DeepSeek V3 leads the pack with exceptional performance in most areas
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="relative overflow-x-auto rounded-xl border border-gray-700/50"
        >
          <div className="overflow-hidden">
            {isMobile ? (
              // Mobile Layout
              <div className="grid gap-4 p-4">
                {benchmarkData.categories.map((category, categoryIndex) => (
                  <div key={categoryIndex} className="bg-gray-800/60 rounded-lg overflow-hidden">
                    <div className="px-4 py-2 text-theme-400 font-semibold border-b border-gray-700/50">
                      {category.name}
                    </div>
                    {category.metrics.map((row, rowIndex) => (
                      <div key={rowIndex} className="p-4 border-b border-gray-700/50 last:border-0">
                        <div className="text-white font-medium mb-2">{row[0]}</div>
                        <div className="grid grid-cols-2 gap-2 text-sm">
                          {benchmarkData.models.map((model, modelIndex) => {
                            const score = row[modelIndex + 1];
                            const isHighest = Math.max(...row.slice(1).map(x => parseFloat(x))) === parseFloat(score);
                            return (
                              <div 
                                key={modelIndex}
                                className={`flex justify-between p-2 rounded ${
                                  isHighest ? 'bg-theme-500/20 text-theme-400' : 'bg-gray-900/50 text-gray-400'
                                }`}
                              >
                                <span className="text-xs">{model}</span>
                                <span>{score}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              // Desktop Layout
              <table className="w-full text-sm">
                <thead>
                  <tr className="bg-gray-800/80">
                    <th className="px-6 py-4 text-left text-gray-300 font-medium">Benchmark (Metric)</th>
                    {benchmarkData.models.map((model, i) => (
                      <th key={i} className="px-6 py-4 text-left text-theme-400 font-medium">
                        {model}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {benchmarkData.categories.map((category, categoryIndex) => (
                    <React.Fragment key={categoryIndex}>
                      <tr className="bg-gray-800/60">
                        <td colSpan={benchmarkData.models.length + 1} className="px-6 py-3 text-theme-400 font-semibold">
                          {category.name}
                        </td>
                      </tr>
                      {category.metrics.map((row, rowIndex) => (
                        <tr key={`${categoryIndex}-${rowIndex}`} className="border-t border-gray-700/50 bg-gray-900/50 hover:bg-gray-800/50 transition-colors">
                          {row.map((cell, cellIndex) => {
                            const isMetricValue = cellIndex > 0;
                            const isHighestInRow = isMetricValue && 
                              Math.max(...row.slice(1).map(x => parseFloat(x))) === parseFloat(cell);
                            
                            return (
                              <td 
                                key={cellIndex}
                                className={`px-6 py-4 ${
                                  cellIndex === 0 
                                    ? 'text-gray-300' 
                                    : isHighestInRow
                                      ? 'text-theme-400 font-medium'
                                      : 'text-gray-400'
                                }`}
                              >
                                {cell}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default BenchmarkSection;