import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Bot, Sparkles, ArrowRight, Zap, HandCoins } from 'lucide-react';
import textLogoDark from '../assets/textlogo_dark.png';
import CostCalculator from './CostCalculator';
import BenchmarkSection from './BenchmarkSection';

const DesktopLanding = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 overflow-hidden text-gray-300">
      {/* Header Section */}
      <header className="h-screen flex flex-col">
      <motion.div 
          className="pt-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <img src={textLogoDark} alt="FlexFlow AI" className="w-64 mx-auto mb-4" />
        </motion.div>

        {/* Hero Section */}
        <section className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-8 px-8 py-4">
          <div className="flex flex-col justify-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-white via-theme-400 to-theme-600 bg-clip-text text-transparent">
                Experience the Future of{' '}
                <span className="bg-gradient-to-r from-theme-400 to-theme-600 bg-clip-text">
                  AI Interaction
                </span>
              </h1>
              <p className="text-lg md:text-xl font-light mb-8">
                Seamlessly switch AI models, generate images, and pay-as-you-go—all in one platform.
              </p>

              <motion.button
                onClick={() => navigate('/login')}
                className="group px-6 py-3 bg-theme-500 rounded-lg text-white text-lg font-medium hover:bg-theme-600 transition-all duration-300 flex items-center gap-2 shadow-lg hover:shadow-xl"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Get Started with 100 Free Credits
                <ArrowRight className="group-hover:translate-x-1 transition-transform" />
              </motion.button>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10">
                {[
                  {
                    icon: <Bot size={24} />, title: "Model Flexibility", desc: "Switch AI models seamlessly mid-conversation."
                  },
                  {
                    icon: <Sparkles size={24} />, title: "Image Generation", desc: "Create stunning visuals from text for just ₹0.50/image."
                  },
                  {
                    icon: <Zap size={24} />, title: "Pay As You Go", desc: "Pay per 500 tokens, only for what you use."
                  },
                  {
                    icon: <HandCoins size={24} />, title: "100 Free Credits", desc: "Start your journey with complimentary credits."
                  }
                ].map((feature, index) => (
                  <motion.div
                    key={index}
                    className="p-4 rounded-xl bg-gray-800 border border-gray-700 hover:bg-gray-700 transition-all duration-300"
                    whileHover={{ y: -5 }}
                  >
                    <div className="flex items-center gap-3 mb-2">
                      <div className="text-theme-500">{feature.icon}</div>
                      <h3 className="text-lg font-medium text-white">{feature.title}</h3>
                    </div>
                    <p className="text-sm">{feature.desc}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>

          {/* Cost Calculator Section */}
          <motion.div 
            className="flex flex-col items-center"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h2 className="text-2xl font-light mb-6 text-center">
              Calculate Your Cost Savings
            </h2>
            <CostCalculator />
          </motion.div>
        </section>
      </header>

      {/* Benchmark Section */}
      <section className="mt-12">
        <BenchmarkSection />
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 py-6 mt-12 border-t border-gray-800">
        <div className="flex justify-between px-8 text-gray-400 text-sm">
          <p>&copy; 2024 FlexFlow. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default DesktopLanding;