import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { EventProvider } from './contexts/EventContext';
import { isMobileDevice } from './utils/deviceDetection';
import Login from './components/Login';
import Landing from './components/Landing';
import ChatInterface from './components/chat/ChatInterface';
import MobileChatInterface from './components/mobile/MobileChatInterface';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import TermsOfUse from './components/legal/TermsOfUse';
import ResetPassword from './components/ResetPassword';



const ProtectedRoute = ({ children }) => {
  const { currentUser, isLoading } = useAuth();

  // Show loading state or spinner while checking auth
  if (isLoading) {
    return null; // Or a loading spinner component
  }

  return currentUser ? children : <Navigate to="/login" />;
};

const AuthenticatedRoute = ({ children }) => {
  const { currentUser, isLoading } = useAuth();

  if (isLoading) {
    return null;
  }

  return currentUser ? <Navigate to="/chat" /> : children;
};

const ChatRoute = () => {
  const isMobile = isMobileDevice();
  return (
    <ProtectedRoute>
      {isMobile ? <MobileChatInterface /> : <ChatInterface />}
    </ProtectedRoute>
  );
};

function AppRoutes() {
  const { currentUser, isLoading } = useAuth();

  // Handle direct /chat access
  if (window.location.pathname === '/chat' && !isLoading && !currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <Routes>
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfUse />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route 
        path="/" 
        element={
          <AuthenticatedRoute>
            <Landing />
          </AuthenticatedRoute>
        } 
      />
      <Route 
        path="/login" 
        element={
          <AuthenticatedRoute>
            <Login />
          </AuthenticatedRoute>
        } 
      />
      <Route
        path="/chat"
        element={<ChatRoute />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

function App() {
  return (
    <AuthProvider>
      <EventProvider>
        <Router>
          <AppRoutes />
        </Router>
      </EventProvider>
    </AuthProvider>
  );
}

export default App;