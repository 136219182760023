import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail, Lock, ArrowLeft, Eye, EyeOff, RefreshCw, Hash } from 'lucide-react';
import axios from 'axios';
import flowLogo from '../assets/flexflowai_logo.png';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [canResend, setCanResend] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const navigate = useNavigate();

  const handleSendOTP = async (e) => {
    e?.preventDefault();
    setLoading(true);
    try {
      await axios.post('/auth/reset-password', { email });
      setStep(2);
      setError('');
      setCanResend(false);
      let timer = 30;
      const interval = setInterval(() => {
        timer--;
        setCountdown(timer);
        if (timer === 0) {
          setCanResend(true);
          clearInterval(interval);
        }
      }, 1000);
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to send OTP');
    }
    setLoading(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    setLoading(true);
    try {
      await axios.post('/auth/update-password', {
        email,
        otp,
        new_password: newPassword
      });
      navigate('/login', { 
        state: { message: 'Password reset successful. Please login with your new password.' }
      });
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to reset password');
    }
    setLoading(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <button 
            onClick={() => navigate('/login')}
            className="flex items-center text-theme-600 hover:text-theme-500"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Login
          </button>
          <div className="mt-6 flex items-center justify-center">
            <img src={flowLogo} alt="Flow AI Logo" className="h-40 w-40" />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset Password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {step === 1 ? "Enter your email to receive a reset code" : "Enter the code sent to your email"}
          </p>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        {step === 1 ? (
          <form onSubmit={handleSendOTP} className="mt-8 space-y-6">
            <div>
              <label className="sr-only">Email address</label>
              <div className="relative flex items-center">
                <Mail className="absolute left-3 text-gray-400" size={20} />
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email address"
                    required
                    className="pl-10 appearance-none w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-theme-500 focus:border-theme-500"
                />
                </div>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-theme-500 hover:bg-theme-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500 disabled:opacity-50"
            >
              {loading ? 'Sending...' : 'Send Reset Code'}
            </button>
          </form>
        ) : (
          <form onSubmit={handleResetPassword} className="mt-8 space-y-6">
            <div className="space-y-4">
              <div>
                <label className="sr-only">Verification Code</label>
                <div className="relative flex items-center">
                <Hash className="absolute left-3 text-gray-400" size={20} />
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter verification code"
                    required
                    className="pl-10 appearance-none w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-theme-500 focus:border-theme-500"
                    />
                  <button
                    type="button"
                    onClick={() => handleSendOTP()}
                    disabled={!canResend}
                    className="absolute right-2 px-3 py-1 rounded-md bg-theme-500 text-white disabled:opacity-50 hover:bg-theme-600 text-sm"
                  >
                    {canResend ? (
                      <div className="flex items-center gap-1">
                        <RefreshCw size={14} />
                        Resend
                      </div>
                    ) : (
                      `${countdown}s`
                    )}
                  </button>
                </div>
              </div>

              <div>
                <label className="sr-only">New Password</label>
                <div className="relative flex items-center">
                <Lock className="absolute left-3 text-gray-400" size={20} />
                  <input
                    type={showPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New password"
                    required
                    className="pl-10 appearance-none w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-theme-500 focus:border-theme-500"
                    />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 text-gray-400 hover:text-gray-600"
                  >
                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                  </button>
                </div>
              </div>

              <div>
                <label className="sr-only">Confirm New Password</label>
                <div className="relative flex items-center">
                  <Lock className="absolute left-3 text-gray-400" size={20} />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm new password"
                    required
                    className="pl-10 appearance-none w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-theme-500 focus:border-theme-500"
                    />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute right-3 text-gray-400 hover:text-gray-600"
                  >
                    {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                  </button>
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-theme-500 hover:bg-theme-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500 disabled:opacity-50"
            >
              {loading ? 'Resetting...' : 'Reset Password'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;