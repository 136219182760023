import React, { createContext, useContext } from 'react';

const EventContext = createContext();

export const useEvent = () => useContext(EventContext);

export const EventProvider = ({ children }) => {
  const triggerCreditUpdate = () => {
    window.dispatchEvent(new CustomEvent('updateCredits'));
  };

  return (
    <EventContext.Provider value={{ triggerCreditUpdate }}>
      {children}
    </EventContext.Provider>
  );
};