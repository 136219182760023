// frontend/src/components/chat/ChatInterface.jsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import Sidebar from './Sidebar';
import { useEvent } from '../../contexts/EventContext';
import { Zap, Sparkles, MessageSquare, Image } from 'lucide-react';
import flowLogo from '../../assets/flexflowai_logo.png';
import OnboardingModal from '../modals/OnboardingModal';

const ChatInterface = () => {
  const { currentUser, logout } = useAuth();
  const { triggerCreditUpdate } = useEvent();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const dropdownRef = useRef(null);
  const sidebarRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);
  
  const [activeTab, setActiveTab] = useState(() => localStorage.getItem('active_tab') || 'chat');
  const [chatHistory, setChatHistory] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('theme') === 'dark');
  const [chatMessages, setChatMessages] = useState(() => {
    const saved = localStorage.getItem('chat_messages');
    return saved ? JSON.parse(saved) : { chat: [], image: [] };
  });
  const [message, setMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(() => 
    localStorage.getItem('selected_model') || 'deepseek-v3'
  );
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [error, setError] = useState(null);

  const models = [
    { id: 1, name: 'deepseek-v3', icon: <Zap size={16} />, credits: 1,description: 'Best at Data Analysis' },
    { id: 2, name: 'llama-3-8b-instruct', icon: <Zap size={16} />, credits: 1,description: 'Best at How-To-Guides' },
    { id: 3, name: 'gpt-4o-mini', icon: <Zap size={16} />, credits: 2,description: 'Best at General Chat' },
    { id: 4, name: 'claude-3.5-haiku', icon: <Zap size={16} />, credits: 2,description: 'Best at Creative Writing' },
    { id: 5, name: 'gpt-4o', icon: <Zap size={16} />, credits: 4,description: 'Best at Summarization' },
    { id: 6, name: 'claude-3.5-sonnet', icon: <Zap size={16} />, credits: 4,description: 'Best at Coding' },
    { id: 7, name: 'deepseek-r1', icon: <Zap size={16} />, credits: 2,description: 'Best at Research' },
  ];

  const imageModels = [
    { id: 1, name: 'flux-schnell', icon: <Sparkles size={16} />, credits: 1,description: 'Animated Images' },
    { id: 2, name: 'sticker-maker', icon: <Sparkles size={16} />, credits: 1,description: 'Sticker Creation' },
    { id: 3, name: 'sdxl-lightning-4step', icon: <Sparkles size={16} />, credits: 1,description: 'Semi Animated Images' }
  ];

  useEffect(() => {
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    document.documentElement.classList.toggle('dark', isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    localStorage.setItem('chat_messages', JSON.stringify(chatMessages));
  }, [chatMessages]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsModelDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('chat_messages');
    localStorage.removeItem('active_tab');
    localStorage.removeItem('selected_model');
    logout();
    navigate('/login');
  }, [logout, navigate]);

  const loadChatHistory = async (chatId) => {
    try {
      setSelectedChatId(chatId);
      const response = await axios.get(`/api/chat/messages/${chatId}`, {
        params: { user_id: currentUser.id }
      });
      
      const formattedMessages = response.data.flatMap(msg => [
        {
          id: `${msg._id}-user`,
          text: msg.content,
          sender: 'user',
          timestamp: msg.timestamp
        },
        {
          id: `${msg._id}-bot`,
          text: msg.response,
          sender: 'bot',
          timestamp: msg.timestamp
        }
      ]);

      setChatMessages(prev => ({
        ...prev,
        [activeTab]: formattedMessages
      }));
      setError(null);
      
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } catch (error) {
      console.error('Error loading chat:', error);
      setError('Failed to load chat history');
    }
  };

  const handleNewChat = useCallback(() => {
    setSelectedChatId(null);
    setChatMessages(prev => ({
      ...prev,
      [activeTab]: []
    }));
    setError(null);
    sidebarRef.current?.fetchChatHistory();
  }, [activeTab]);

  const handleSubmit = async (e, fileData, tokenCount) => {
    e.preventDefault();
    if (!message.trim()) return;

    const currentSessionId = selectedChatId || new Date().getTime().toString();
    const userMessage = {
      id: Date.now(),
      text: message.trim(),
      sender: 'user',
      timestamp: new Date().toISOString(),
      model: selectedModel,
      session_id: currentSessionId,
      token_count: tokenCount
    };

    setChatMessages(prev => ({
      ...prev,
      [activeTab]: [...prev[activeTab], userMessage]
    }));
    setMessage('');
    setIsTyping(true);
    setError(null);

    try {
      const endpoint = activeTab === 'image' ? '/api/generate' : '/api/chat';
      const payload = activeTab === 'image' ? {
        prompt: userMessage.text,
        negative_prompt: "never show explicit content,never show nudity,never show violence,never show hate symbols,never show offensive language,never show illegal activities,never show harmful stereotypes,never show political propaganda,never show misleading visuals,never show sensitive topics,never show traumatic topics,never show privacy violations,never show intellectual property infringement,never show unsafe actions,never show dehumanizing depictions, and always respect copyright",
        user_id: currentUser.id,
        model: selectedModel
      } : {
        content: userMessage.text,
        model: selectedModel,
        user_id: currentUser.id,
        token_count: tokenCount,
        context: chatMessages[activeTab].slice(-5).map(msg => ({
          content: msg.text,
          response: msg.sender === 'bot' ? msg.text : '',
          role: msg.sender
        })),
        session_id: currentSessionId,
        file_content: fileData
      };

      const response = await axios.post(endpoint, payload);
      
      const botMessage = {
        id: Date.now() + 1,
        text: activeTab === 'image' ? response.data.image_url : response.data.content,
        sender: 'bot',
        timestamp: new Date().toISOString(),
        model: selectedModel,
        session_id: currentSessionId,
        isImage: activeTab === 'image'
      };

      setChatMessages(prev => ({
        ...prev,
        [activeTab]: [...prev[activeTab], botMessage]
      }));
      setSelectedChatId(currentSessionId);
      triggerCreditUpdate();
      sidebarRef.current?.fetchChatHistory();

    } catch (error) {
      console.error('Error:', error);
      if (error.response?.status === 402) {
        setError('Insufficient credits. Please purchase more credits to continue.');
      } else if (error.response?.status === 400 && error.response?.data?.detail?.includes('flagged')) {
        const flaggedContent = error.response.data.detail;
        setError(`Message flagged by content moderation for: ${flaggedContent}. Please revise your message and try again.`);
      } else {
        setError(error.response?.data?.detail || 'Failed to send message');
      }
      setChatMessages(prev => ({
        ...prev,
        [activeTab]: prev[activeTab].filter(msg => msg.id !== userMessage.id)
      }));
    } finally {
      setIsTyping(false);
    }
  };

  useEffect(() => {
    if (activeTab === 'image' && !imageModels.some(m => m.name === selectedModel)) {
      setSelectedModel('flux-schnell');
    } else if (activeTab === 'chat' && !models.some(m => m.name === selectedModel)) {
      setSelectedModel('deepseek-v3');
    }
  }, [activeTab]);

  const handleTabChange = useCallback((tab) => {
    const defaultModel = tab === 'image' ? 'flux-schnell' : 'deepseek-v3';
    setSelectedModel(defaultModel);
    setActiveTab(tab);
    setChatHistory([]);
    localStorage.setItem('active_tab', tab);
    localStorage.setItem('selected_model', defaultModel);
  }, []);

  const EmptyState = ({ isDarkMode, activeTab }) => (
    <div className="h-full flex flex-col items-center justify-center">
      <div className={`p-4 rounded-full ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} mb-4`}>
        {activeTab === 'chat' ? (
          <MessageSquare size={32} className="text-theme-500" />
        ) : (
          <Image size={32} className="text-theme-500" />
        )}
      </div>
      <h2 className={`text-xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {activeTab === 'chat' ? 'Start a conversation' : 'Create an image'}
      </h2>
      <p className={`text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        {activeTab === 'chat' 
          ? 'Choose a model and start chatting'
          : 'Describe an image you want to generate'
        }
      </p>
    </div>
  );

  return (
    <div className="flex h-screen p-4 bg-gray-100 dark:bg-gray-950 overflow-hidden">
      <div className={`transition-all duration-200 flex-shrink-0 ${
        isSidebarOpen ? 'w-64 mr-4' : 'w-0'
      }`}>
        <div className={`h-[96.5vh] rounded-3xl shadow-lg overflow-hidden bg-white dark:bg-gray-900 transition-all duration-200 ${
          isSidebarOpen ? 'w-64' : 'w-0'
        }`}>
          <Sidebar
            ref={sidebarRef}
            currentUser={currentUser}
            isDarkMode={isDarkMode}
            handleLogout={handleLogout}
            flowLogo={flowLogo}
            onChatSelect={loadChatHistory}
            selectedChatId={selectedChatId}
            onNewChat={handleNewChat}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
          />
        </div>
      </div>
      
      <div className="flex-1 flex flex-col space-y-4 min-w-0">
        <div className="rounded-2xl shadow-lg overflow-hidden bg-white dark:bg-gray-900 transition-all duration-200">
          <ChatHeader
            isDarkMode={isDarkMode}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            setIsDarkMode={setIsDarkMode}
            models={activeTab === 'image' ? imageModels : models}
            isModelDropdownOpen={isModelDropdownOpen}
            setIsModelDropdownOpen={setIsModelDropdownOpen}
            dropdownRef={dropdownRef}
            onNewChat={handleNewChat}
            activeTab={activeTab}
            toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
          />
        </div>
        
        <div className="flex-1 rounded-2xl shadow-lg overflow-hidden bg-white dark:bg-gray-900 transition-all duration-200">
          <div className="h-full overflow-y-auto p-4">
            {error && (
              <div className="max-w-4xl mx-auto mb-4">
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-xl relative">
                  <span className="block sm:inline">{error}</span>
                </div>
              </div>
            )}
            
            {chatMessages[activeTab].length === 0 ? (
              <EmptyState isDarkMode={isDarkMode} activeTab={activeTab} />
            ) : (
              <ChatMessages
                messages={searchQuery 
                  ? chatMessages[activeTab].filter(msg => 
                      msg.text.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                  : chatMessages[activeTab]}
                searchQuery={searchQuery}
                isDarkMode={isDarkMode}
                isTyping={isTyping}
                messagesEndRef={messagesEndRef}
                isImageMode={activeTab === 'image'}
              />
            )}
          </div>
        </div>
        
        <div className="rounded-2xl shadow-lg overflow-hidden bg-white dark:bg-gray-900 transition-all duration-200">
          <ChatInput
            message={message}
            setMessage={setMessage}
            handleSubmit={handleSubmit}
            isDarkMode={isDarkMode}
            activeTab={activeTab}
            error={error}
            selectedModel={selectedModel}
            selectedChatId={selectedChatId}
            setShowOnboarding={setShowOnboarding} 
          />
          <OnboardingModal 
          isOpen={showOnboarding}
          onClose={() => setShowOnboarding(false)}
          isDarkMode={isDarkMode}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;