import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

// Reusable component for legal pages (Privacy Policy and Terms of Use)
const LegalPage = ({ title, sections }) => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      <div className="max-w-4xl mx-auto px-6 py-12">
        <Link 
          to="/login" 
          className="inline-flex items-center text-gray-400 hover:text-white transition-colors mb-8 group"
        >
          <ArrowLeft className="mr-2 group-hover:-translate-x-1 transition-transform" size={20} />
          Back to Login
        </Link>
        
        <h1 className="text-4xl font-bold bg-gradient-to-r from-white via-theme-400 to-theme-600 bg-clip-text text-transparent mb-12">
          {title}
        </h1>
        
        <div className="space-y-12">
          {sections.map((section, index) => (
            <div 
              key={index} 
              className="p-6 rounded-xl bg-gray-800/50 border border-gray-700/50 hover:bg-gray-700/30 transition-colors"
            >
              <h2 className="text-xl font-semibold text-white mb-4">
                {section.title}
              </h2>
              {section.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Lists with improved styling
const List = ({ items, type = "bullet" }) => (
  <ul className="space-y-2 text-gray-300 ml-6">
    {items.map((item, index) => (
      <li 
        key={index}
        className={`relative pl-4 before:absolute before:left-0 before:top-2.5 ${
          type === "bullet" 
            ? "before:w-1.5 before:h-1.5 before:bg-theme-500 before:rounded-full"
            : "before:content-[counter(list-item)] before:-left-4 before:text-theme-500"
        }`}
      >
        {item}
      </li>
    ))}
  </ul>
);

// Paragraph with improved styling
const Paragraph = ({ children }) => (
  <p className="text-gray-300 leading-relaxed">
    {children}
  </p>
);

// Section content with consistent spacing
const Section = ({ children }) => (
  <div className="space-y-4">
    {children}
  </div>
);

export { LegalPage, List, Paragraph, Section };