import React, { useState, useEffect } from 'react';
import { Calculator } from 'lucide-react';

const CostCalculator = () => {
  const [queryCount, setQueryCount] = useState(1000);
  const [savings, setSavings] = useState(0);

  // Constants for cost calculation
  const AI_MEMBERSHIP_COST = 2000;  // Fixed ₹1750 membership
  const FLEXFLOW_COST = {
    credits: 100,              // Free credits
    costPerQuery: 0.5,        // ₹0.5 per query after free credits
    maxQueries: 4000          // Maximum queries calculated
  };

  useEffect(() => {
    // Calculate savings
    const flexflowCost = Math.max(0, queryCount - FLEXFLOW_COST.credits) * FLEXFLOW_COST.costPerQuery;
    const calculatedSavings = AI_MEMBERSHIP_COST - flexflowCost;
    setSavings(calculatedSavings);
  }, [queryCount]);

  return (
    <div className="w-full max-w-xl mx-auto bg-gradient-to-b from-theme-500/10 to-theme-500/5 backdrop-blur-sm border border-theme-500/20 p-6 rounded-2xl shadow-xl">
      <div className="flex items-center gap-3 mb-8">
        <div className="p-2 rounded-lg bg-theme-500/20">
          <Calculator className="text-theme-500" size={24} />
        </div>
        <h3 className="text-xl font-semibold text-white">Cost Calculator</h3>
      </div>

      <div className="space-y-8">
        <div className="space-y-3">
          <div className="flex justify-between text-sm">
            <span className="text-gray-300 font-medium">Number of Queries (per month)</span>
            <span className="text-theme-400 font-semibold">{queryCount.toLocaleString()}</span>
          </div>
          <input
            type="range"
            min="100"
            max={FLEXFLOW_COST.maxQueries}
            step="50"
            value={queryCount}
            onChange={(e) => setQueryCount(Number(e.target.value))}
            className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer accent-theme-500 focus:outline-none focus:ring-2 focus:ring-theme-500/50"
          />
          <div className="flex justify-between text-xs text-gray-500">
            <span>100</span>
            <span>{FLEXFLOW_COST.maxQueries}</span>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700/50">
            <div className="text-sm text-gray-400 mb-2">Avg AI Subscription Cost</div>
            <div className="text-2xl font-bold text-white">
              ₹{AI_MEMBERSHIP_COST.toFixed(0)}
              <span className="text-sm text-gray-400 font-normal ml-1">/month</span>
            </div>
          </div>
          <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700/50">
            <div className="text-sm text-gray-400 mb-2">FlexFlow Cost*</div>
            <div className="text-2xl font-bold text-white">
              ₹{Math.max(0, (queryCount - FLEXFLOW_COST.credits) * FLEXFLOW_COST.costPerQuery).toFixed(0)}
              <span className="text-sm text-gray-400 font-normal ml-1">/month</span>
            </div>
          </div>
        </div>

        <div className="p-4 rounded-xl bg-theme-500/10 border border-theme-500/20">
          <div className="flex justify-between items-baseline mb-2">
            <div className="text-sm font-medium text-theme-300">Potential Savings</div>
            <div className="text-2xl font-bold text-theme-400">
              ₹{Math.max(0, savings).toFixed(0)}
              <span className="text-sm text-theme-300 font-normal ml-1">per month</span>
            </div>
          </div>
          {queryCount <= FLEXFLOW_COST.credits && (
            <div className="text-sm text-theme-300 bg-theme-500/20 px-3 py-2 rounded-lg">
              ✨ First {FLEXFLOW_COST.credits} queries are completely free!
            </div>
          )}
          <div className="mt-2 text-xs text-theme-300/80 italic">
            * Based on using our current favourite model
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostCalculator;