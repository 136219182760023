import React, { useState, useRef, useEffect } from 'react';
import { ArrowUp, X, FileSpreadsheet, Info } from 'lucide-react';
import FileUpload from '../FileUpload';
import { MODEL_CONFIG } from '../../config/modelConfig';

const estimateTokenCount = (text) => {
  if (!text) return 0;
  const asciiCharCount = [...text].filter(char => char.charCodeAt(0) <= 127).length;
  return Math.max(1, Math.ceil(asciiCharCount / 4));
};

const TokenInfoTooltip = ({ isDarkMode, showTooltip }) => {
  if (!showTooltip) return null;
  
  return (
    <div
      className={`absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-4 py-2 text-xs rounded-lg shadow-lg ${
        isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-200 text-gray-600'
      }`}
      style={{
        minWidth: '200px', // Set a minimum width
        maxWidth: '300px', // Optional maximum width for responsiveness
        textAlign: 'center', // Center-align content
      }}
    >
      <div className="mb-1">Approx 4 chars = 1 Token</div>
      <div className="mb-1">Credits : Total Run Cost</div>
      {/* Arrow Indicator */}
      <div className="absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-45 w-2 h-2 bg-inherit"></div>
    </div>
  );
};


const getCreditCost = (tokens, selectedModel) => {
  const MODEL_COSTS = {
    "deepseek-v3": {
      base_tokens: 500,
      token_cost: 1,
      increment_cost: 1
    },
    "deepseek-r1": {
      base_tokens: 500,
      token_cost: 2,
      increment_cost: 2
    },
    "llama-3-8b-instruct": {
      base_tokens: 500,
      token_cost: 1,
      increment_cost: 1
    },
    "gpt-4o-mini": {
      base_tokens: 500,
      token_cost: 2,
      increment_cost: 2
    },
    "gpt-4o": {
      base_tokens: 500,
      token_cost: 4,
      increment_cost: 4
    },
    "claude-3.5-haiku": {
      base_tokens: 500,
      token_cost: 2,
      increment_cost: 2
    },
    "claude-3.5-sonnet": {
      base_tokens: 500,
      token_cost: 4,
      increment_cost: 4
    }
  };

  const modelConfig = MODEL_COSTS[selectedModel];
  if (!modelConfig) return 1;

  const baseCost = modelConfig.token_cost;
  const remainingTokens = Math.max(0, tokens - modelConfig.base_tokens);
  const incrementCost = Math.ceil(remainingTokens / 500) * modelConfig.increment_cost;

  return baseCost + incrementCost;
};

const MobileChatInput = ({
  message,
  setMessage,
  handleSubmit,
  isDarkMode,
  activeTab,
  error,
  selectedModel,
  selectedChatId,
  setShowOnboarding
}) => {
  const textareaRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [tokenCount, setTokenCount] = useState(0);
  const [showTokenTooltip, setShowTokenTooltip] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (showTokenTooltip) {
      const timeoutId = setTimeout(() => setShowTokenTooltip(false), 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [showTokenTooltip]);

  useEffect(() => {
    setUploadedFile(null);
    setUploadedFileName(null);
  }, [selectedChatId, activeTab]);

  useEffect(() => {
    let timeoutId;
    if (error?.includes('Insufficient credits') || error?.includes('Content flagged')) {
      setShowError(true);
      timeoutId = setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [error]);

  useEffect(() => {
    let totalTokens = estimateTokenCount(message);
    
    if (uploadedFile) {
      if (uploadedFile.type === 'png' || uploadedFile.type === 'jpg') {
        totalTokens += estimateTokenCount(uploadedFile.content);
      } else if (typeof uploadedFile.content === 'object') {
        totalTokens += estimateTokenCount(JSON.stringify(uploadedFile.content));
      } else if (typeof uploadedFile.content === 'string') {
        totalTokens += estimateTokenCount(uploadedFile.content);
      } else if (typeof uploadedFile.content === 'pdf') {
        totalTokens += estimateTokenCount(uploadedFile.content);
      }
    }
    
    setTokenCount(totalTokens);
  }, [message, uploadedFile]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px`;
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!message.trim() && !uploadedFile) return;
    handleSubmit(e, uploadedFile, tokenCount);
    setMessage('');
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  };

  const handleFileUpload = (fileData) => {
    setUploadedFileName(fileData ? fileData.originalName || fileData.type : null);
    setUploadedFile(fileData);
  };

  return (
    <div className={`px-2 pb-safe-area-inset-bottom`}>
      <div className="max-w-4xl mx-auto">
        {uploadedFileName && (
          <div className={`mt-2 p-2 rounded-lg flex items-center gap-2 w-fit ${
            isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
          }`}>
            <FileSpreadsheet size={20} className="text-gray-500" />
            <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              {uploadedFileName}
            </span>
            <button
              onClick={() => handleFileUpload(null)}
              className={`p-1 rounded-full ${isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-200'}`}
            >
              <X size={16} className="text-gray-500" />
            </button>
          </div>
        )}

        <form onSubmit={handleFormSubmit} className="relative flex flex-col w-full py-2">
          <div className="relative flex items-center">
            {MODEL_CONFIG[selectedModel]?.supportsFiles && (
              <div className="mr-2">
                <FileUpload
                  isDarkMode={isDarkMode}
                  onFileUpload={handleFileUpload}
                  selectedModel={selectedModel}
                />
              </div>
            )}

            <div className="relative flex-1">
              {error?.includes('Insufficient credits') && showError && (
                <div className="absolute -top-16 left-0 right-0 bg-red-100 text-red-600 px-3 py-2 rounded-lg text-sm">
                  Insufficient credits. Please purchase more credits to continue.
                </div>
              )}
              {error?.includes('Content flagged') && showError && (
                <div className="absolute -top-16 left-0 right-0 bg-red-100 text-red-600 px-3 py-2 rounded-lg text-sm">
                  {`Content flagged by moderation${error.split('for:')[1] ? `: ${error.split('for:')[1]}` : ''}. Please revise and try again.`}
                </div>
              )}
              <textarea
                ref={textareaRef}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  adjustTextareaHeight();
                }}
                placeholder={activeTab === 'chat' ? 'Type your message here...' : 'Describe an image...'}
                className={`w-full min-h-[42px] max-h-[120px] p-3 pr-12 rounded-2xl border resize-none overflow-auto ${
                  isDarkMode
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                    : 'bg-white border-gray-300 placeholder-gray-500'
                } focus:ring-theme-500 focus:border-theme-500 outline-none`}
                rows={1}
              />
              {message.trim() && (
                <button
                  type="submit"
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 flex items-center justify-center h-8 w-8 bg-theme-500 text-white rounded-full hover:bg-theme-600 focus:outline-none"
                >
                  <ArrowUp size={16} />
                </button>
              )}
            </div>
          </div>

          <div className="relative w-full mt-0.5" style={{ height: '2rem' }}>
          {/* Token Counter - Centered */}
          <div
            className={`absolute left-1/2 transform -translate-x-1/2 px-2 py-0.5 text-xs inline-flex items-center justify-center rounded ${
              isDarkMode
                ? 'bg-gray-700 text-gray-300'
                : 'bg-gray-200 text-gray-600'
            }`}
            onClick={() => setShowTokenTooltip(!showTokenTooltip)}
          >
            <TokenInfoTooltip isDarkMode={isDarkMode} showTooltip={showTokenTooltip} />
            {tokenCount} {tokenCount === 1 ? 'token' : 'tokens'} • {getCreditCost(tokenCount, selectedModel)}{' '}
            {getCreditCost(tokenCount, selectedModel) === 1 ? 'credit' : 'credits'}
          </div>

          {/* info Button - Aligned to the Right */}
          <button
            type="button"
            onClick={() => setShowOnboarding(true)}
            className={`absolute right-0 p-0 rounded-lg transition-colors ${
              isDarkMode ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-100 text-gray-500'
            }`}
          >
            <Info size={16} />
          </button>
        </div>
        </form>
      </div>
    </div>
  );
};

export default MobileChatInput;