import React, { useState, useEffect } from 'react';
import { Menu, Search, Zap, Sun, Moon, MessageCirclePlus, X, ChevronDown, Bot, Image } from 'lucide-react';

const MobileHeader = ({
  isDarkMode,
  setIsDarkMode,
  searchQuery,
  setSearchQuery,
  selectedModel,
  setSelectedModel,
  models,
  onNewChat,
  toggleSidebar,
  activeTab,
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isModelSelectorOpen, setIsModelSelectorOpen] = useState(false);

  useEffect(() => {
    if (activeTab === 'image') {
      setSelectedModel('flux-schnell'); // Set default image model
    } else {
      setSelectedModel('deepseek-v3'); // Set default chat model
    }
  }, [activeTab]); // Only re-run when activeTab changes

  return (
    <>
      <div className={`h-20 ${isDarkMode ? 'bg-gray-900 border-gray-700' : 'bg-white border-gray-200'} border-b px-4`}>
        <div className="h-full flex items-center justify-between">
          {/* Left Section */}
          <div className="flex items-center gap-4">
            <button
              onClick={toggleSidebar}
              className={`p-2 rounded-lg ${
                isDarkMode ? 'hover:bg-gray-800 text-gray-400' : 'hover:bg-gray-100 text-gray-600'
              }`}
            >
              <Menu size={20} />
            </button>

            <button
              onClick={() => setIsSearchOpen(true)}
              className={`p-2 rounded-lg ${
                isDarkMode ? 'hover:bg-gray-800 text-gray-400' : 'hover:bg-gray-100 text-gray-600'
              }`}
            >
              <Search size={16} />
            </button>
          </div>

          {/* Center Section */}
          <div className="flex flex-col items-center gap-1">
            <div className={`p-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              {activeTab === 'chat' ? <Bot size={20} /> : <Image size={20} />}
            </div>
            <button
              onClick={() => setIsModelSelectorOpen(true)}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg ${
                isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900'
              }`}
            >
              <span
                className="font-medium text-sm whitespace-nowrap"
                title={selectedModel}
              >
                {selectedModel}
              </span>
              <ChevronDown size={16} />
            </button>
          </div>

          {/* Right Section */}
          <div className="flex items-center gap-2">
            <button
              onClick={() => setIsDarkMode(!isDarkMode)}
              className={`p-2 rounded-lg ${
                isDarkMode ? 'bg-gray-800 text-theme-500' : 'bg-gray-100 text-theme-500'
              }`}
            >
              {isDarkMode ? <Moon size={20} /> : <Sun size={20} />}
            </button>

            <button
              onClick={onNewChat}
              className={`p-2 rounded-lg ${
                isDarkMode ? 'bg-gray-800 text-gray-400' : 'bg-gray-100 text-gray-600'
              }`}
            >
              <MessageCirclePlus size={20} />
            </button>
          </div>
        </div>
      </div>

      {/* Search Overlay */}
      {isSearchOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
          <div className={`h-32 ${isDarkMode ? 'bg-gray-900' : 'bg-white'} p-4`}>
            <div className="flex items-center justify-between mb-4">
              <h2 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Search</h2>
              <button
                onClick={() => setIsSearchOpen(false)}
                className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}
              >
                <X size={24} />
              </button>
            </div>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search in chat..."
              className={`w-full px-4 py-2 rounded-lg ${
                isDarkMode
                  ? 'bg-gray-800 text-white placeholder-gray-400 border-gray-700'
                  : 'bg-gray-100 text-gray-900 placeholder-gray-500 border-gray-200'
              } border focus:outline-none focus:ring-2 focus:ring-theme-500`}
              autoFocus
            />
          </div>
        </div>
      )}

      {/* Model Selector Overlay */}
      {isModelSelectorOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
          <div
            className={`absolute bottom-0 left-0 right-0 ${
              isDarkMode ? 'bg-gray-900' : 'bg-white'
            } rounded-t-xl p-4`}
          >
            <div className="flex justify-between items-center mb-4">
              <h2
                className={`text-lg font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}
              >
                Select Model
              </h2>
              <button
                onClick={() => setIsModelSelectorOpen(false)}
                className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}
              >
                <X size={24} />
              </button>
            </div>
            <div className="space-y-2 max-h-[50vh] overflow-y-auto">
              {models.map((model) => (
                <button
  key={model.id}
  onClick={() => {
    setSelectedModel(model.name);
    setIsModelSelectorOpen(false);
  }}
  className={`flex items-center justify-between w-full p-3 rounded-lg ${
    selectedModel === model.name
      ? isDarkMode
        ? 'bg-gray-800 text-white'
        : 'bg-gray-100 text-gray-900'
      : isDarkMode
      ? 'text-gray-300'
      : 'text-gray-600'
  }`}
>
  <div className="flex items-center gap-2">
    {model.icon}
    <div className="text-left">
      <span className="block">{model.name}</span>
      <span className={`block text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
        {model.description}
      </span>
    </div>
  </div>
  <span className="text-sm text-gray-500">
    {model.credits} {model.credits === 1 ? 'credit' : 'credits'}/run
  </span>
</button>

              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileHeader;
