// frontend/src/components/modals/InfoModal.jsx

import React, { useState } from 'react';
import { X, Info, Settings, Bug, Shield } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';

const InfoModal = ({ isOpen, onClose, isDarkMode }) => {
  const [selectedSection, setSelectedSection] = useState('about');

  const { currentUser, setCurrentUser } = useAuth();
    const [formData, setFormData] = useState({
    name: currentUser?.name || '',
    email: currentUser?.email || '',
    password: '',
    newPassword: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [bugType, setBugType] = useState('');
    const [pageName, setPageName] = useState('');
    const [description, setDescription] = useState('');

    const handleUpdate = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    const updateData = {};
    if (formData.name !== currentUser.name) updateData.name = formData.name;
    if (formData.email !== currentUser.email) updateData.email = formData.email;
    if (formData.newPassword) updateData.password = formData.newPassword;

    try {
        const response = await axios.put(`/auth/user/update/${currentUser.id}`, updateData);
        setCurrentUser({...currentUser, ...response.data});
        setSuccess('Profile updated successfully');
    } catch (err) {
        setError(err.response?.data?.detail || 'Update failed');
    }
    };

  if (!isOpen) return null;

  const menuItems = [
    { id: 'about', icon: <Info size={20} />, label: 'About' },
    { id: 'profile', icon: <Settings size={20} />, label: 'Profile Settings' },
    { id: 'privacy', icon: <Shield size={20} />, label: 'Privacy & Security' },
    { id: 'bug', icon: <Bug size={20} />, label: 'Report a Bug' }
  ];

  const renderContent = () => {
    switch (selectedSection) {

        case 'about':
            return (
              <div className="space-y-6">
                <div className="top-0 bg-inherit z-10 py-2">
                  <div className="flex items-center space-x-3 mb-6">
                    <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      About Flex Flow AI
                    </h2>
                    <span className={`px-3 py-1 rounded-full text-sm ${isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-600'}`}>
                      v1.0.0
                    </span>
                  </div>
                </div>
           
                <div className={`space-y-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  <div className="border-l-4 border-blue-500 pl-4">
                    <p className="text-lg">
                      Welcome to <span className="font-semibold">Flex Flow AI</span>, your ultimate platform 
                      for seamless access to the world of Language Model intelligence! We believe that every 
                      user deserves the freedom to choose the AI model that best fits their unique needs.
                    </p>
                  </div>
           
                  <div className="space-y-4">
                    <h3 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      What We Offer
                    </h3>
                    
                    <div className="grid gap-4">
                      <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'}`}>
                        <h4 className={`text-lg font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                          Diverse Model Selection
                        </h4>
                        <p>From cutting-edge general-purpose models to specialized tools tailored for 
                          specific domains, Flex Flow AI aggregates multiple Language Learning Models (LLMs) 
                          for a truly flexible experience.</p>
                      </div>
           
                      <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'}`}>
                        <h4 className={`text-lg font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                          Ease of Use
                        </h4>
                        <p>Our intuitive interface lets you switch between models effortlessly. Test, 
                          compare, and integrate models into your workflow without the technical hassle.</p>
                      </div>
           
                      <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'}`}>
                        <h4 className={`text-lg font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                          Customizable Workflow
                        </h4>
                        <p>Choose the model that aligns with your goals, whether it's for content creation, 
                          code generation, data analysis, or advanced research. Flex Flow AI adapts to your 
                          workflow so you can maximize productivity.</p> 

                      </div>

                      <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'}`}>
                        <h4 className={`text-lg font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        Transparent Performance Insights
                        </h4>
                        <p>Compare models based on benchmarks, use cases, and community reviews. 
                            Get all the details you need to make informed decisions 
                            about the AI that's best for your projects.
                        </p> 
                    
                      </div>

                      <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'}`}>
                        <h4 className={`text-lg font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        Future-Ready Innovation
                        </h4>
                        <p>Stay ahead of the curve with continuous updates. 
                            As new models emerge, they’ll be integrated into our platform, 
                            giving you immediate access to the latest advancements in AI technology.
                        </p> 
                      </div>

                      <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'}`}>
                        <h4 className={`text-lg font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        Our Mission
                        </h4>
                        <p>At Flex Flow AI, we are committed to democratizing access to the best in artificial intelligence. 
                            We aim to empower individuals and businesses by providing a platform where innovation meets flexibility. 
                            With Flex Flow AI, you have the power to choose, compare, and create without limits.
                        </p> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );

        case 'profile':
            return (
              <form onSubmit={handleUpdate} className="space-y-4">
                <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Profile Settings
                </h2>
                
                {error && <div className="text-red-500 text-sm">{error}</div>}
                {success && <div className="text-green-500 text-sm">{success}</div>}
          
                <div className="space-y-2">
                  <label className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Name</label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={e => setFormData({...formData, name: e.target.value})}
                    className={`w-full p-2 rounded-lg border ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300'}`}
                  />
                </div>
          
                <div className="space-y-2">
                  <label className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Email</label>
                  <input
                    type="email"
                    disabled
                    value={formData.email}
                    onChange={e => setFormData({...formData, email: e.target.value})}
                    className={`w-full p-2 rounded-lg border ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}
                  />
                </div>
          
                <div className="space-y-2">
                  <label className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>New Password</label>
                  <input
                    type="password"
                    value={formData.newPassword}
                    onChange={e => setFormData({...formData, newPassword: e.target.value})}
                    className={`w-full p-2 rounded-lg border ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300'}`}
                    placeholder="Leave blank to keep current password"
                  />
                </div>
          
                <button
                  type="submit"
                  className="w-full bg-theme-500 text-white py-2 rounded-lg hover:bg-theme-600"
                >
                  Update Profile
                </button>
              </form>
            );

      case 'privacy':
        return (
          <div className="space-y-4">
            <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Privacy & Security</h2>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}><section>
            <h2>1. Information We Collect</h2>
            <h3>Account Information</h3>
            <ul>
              <li>Name and email address</li>
              <li>Account credentials</li>
              <li>Payment information when purchasing credits</li>
            </ul>

            <h3>Usage Information</h3>
            <ul>
              <li>Chat history and generated content</li>
              <li>Credit usage and transaction history</li>
              <li>Service interaction patterns</li>
            </ul>
          </section>

          <section>
            <h2>2. How We Use Your Information</h2>
            <ul>
              <li>To provide and maintain our service</li>
              <li>To process payments and manage credits</li>
              <li>To improve our service and user experience</li>
              <li>To detect and prevent fraudulent activity</li>
              <li>To communicate service updates and promotional offers</li>
            </ul>
          </section>

          <section>
            <h2>3. Data Storage and Security</h2>
            <p>We implement industry-standard security measures to protect your data. Chat histories and generated content are stored securely and accessible only to you.</p>
          </section>

          <section>
            <h2>4. Content Moderation</h2>
            <p>User prompts are analyzed by our content moderation system to ensure compliance with our content guidelines. This analysis is automated and does not store rejected prompts.</p>
          </section>

          <section>
            <h2>5. Data Sharing</h2>
            <p>We do not sell your personal information. We may share data with:</p>
            <ul>
              <li>Service providers (payment processing, hosting)</li>
              <li>AI model providers for request processing</li>
              <li>Law enforcement when required by law</li>
            </ul>
          </section>

          <section>
            <h2>6. Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
              <li>Access your personal data</li>
              <li>Request data deletion</li>
              <li>Export your chat history</li>
              <li>Opt-out of promotional communications</li>
            </ul>
          </section>

          <section>
            <h2>7. Cookies and Tracking</h2>
            <p>We use essential cookies to maintain your session and preferences. No third-party tracking cookies are used.</p>
          </section>

          <section>
            <h2>8. Children's Privacy</h2>
            <p>Our service is not intended for users under 13 years of age. We do not knowingly collect information from children.</p>
          </section>

          <section>
            <h2>9. Changes to Privacy Policy</h2>
            <p>We may update this policy periodically. Users will be notified of significant changes via email or service notification.</p>
          </section>

          <section>
            <h2>10. Contact Us</h2>
            <p>For privacy-related inquiries, contact us at social@flexflow.in</p>
          </section></p>
          </div>
        );
case 'bug':
  return (
    <div className="space-y-4">
      <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Report an Issue
      </h2>
      
      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
          {success}
        </div>
      )}

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          {error}
        </div>
      )}
      
      <form onSubmit={async (e) => {
        e.preventDefault();
        try {
          await axios.post('/auth/bug-report', {
            user_id: currentUser.id,
            username: currentUser.name,
            type: bugType,
            page_name: pageName,
            description: description
          });
          setBugType('');
          setPageName('');
          setDescription('');
          setSuccess('Bug report submitted successfully!');
          setTimeout(() => setSuccess(''), 3000);
        } catch (error) {
          setError('Failed to submit report');
          setTimeout(() => setError(''), 3000);
        }
      }} className="space-y-4">
                  <div>
                    <label className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                      Type
                    </label>
                    <select
                      value={bugType}
                      onChange={(e) => setBugType(e.target.value)}
                      className={`mt-1 block w-full rounded-md border ${
                        isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300'
                      } px-3 py-2`}
                      required
                    >
                      <option value="">Select type</option>
                      <option value="bug">Bug</option>
                      <option value="feature">Feature Request</option>
                      <option value="workflow">Workflow Issue</option>
                      <option value="security">Security Issue</option>
                      <option value="suggestion">General Suggestion</option>
                    </select>
                  </div>
          
                  <div>
                    <label className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                      Page/Feature Name
                    </label>
                    <input
                      type="text"
                      value={pageName}
                      onChange={(e) => setPageName(e.target.value)}
                      className={`mt-1 block w-full rounded-md border ${
                        isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300'
                      } px-3 py-2`}
                      required
                    />
                  </div>
          
                  <div>
                    <label className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                      Description
                    </label>
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={4}
                      className={`mt-1 block w-full rounded-md border ${
                        isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300'
                      } px-3 py-2`}
                      required
                    />
                  </div>
          
                  <button
                    type="submit"
                    className="w-full bg-theme-500 text-white py-2 rounded-lg hover:bg-theme-600"
                  >
                    Submit Report
                  </button>
                </form>
              </div>
            );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className={`w-full max-w-4xl h-[600px] flex rounded-lg overflow-hidden ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      {/* Sidebar */}
      <div className={`w-64 border-r ${isDarkMode ? 'bg-gray-900 border-gray-700' : 'bg-gray-50 border-gray-200'}`}>
          <div className="p-4">
            <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Settings</h2>
          </div>
          <nav className="space-y-1">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setSelectedSection(item.id)}
                className={`w-full flex items-center gap-3 px-4 py-3 text-left ${
                  selectedSection === item.id
                    ? isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
                    : isDarkMode ? 'text-gray-400 hover:bg-gray-800' : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                {item.icon}
                <span>{item.label}</span>
              </button>
            ))}
          </nav>
        </div>

      <div className="flex-1 flex flex-col h-full">
      <div className="flex justify-end p-4">
        <button 
            onClick={onClose} 
            className={`p-2 rounded-full transition-colors ${
            isDarkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-700'
            }`}
        >
            <X size={24} />
        </button>
        </div>
        <div className="flex-1 overflow-y-auto px-6">
          {renderContent()}
        </div>
      </div>
    </div>
  </div>
);
};

export default InfoModal;